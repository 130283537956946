import React, { FC } from 'react';
import style from 'app/modules/mediaLibrary/components/mediaLibraryModal/albumSelect/Placeholder.module.scss';
import AlbumItem from 'app/modules/mediaLibrary/components/mediaLibraryModal/albumSelect/AlbumItem';
import { MediaLibraryAlbum } from 'types/MediaLibrary';
import { Icon } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
type Props = {
  onClick: () => void;
  album?: MediaLibraryAlbum;
};

const Placeholder: FC<Props> = ({ onClick, album }) => (
  <div className={style.button} tabIndex={0} onClick={onClick}>
    {album && <AlbumItem album={album} />}
    {!album && <div className={style.label}>Select album...</div>}
    <Icon icon={faChevronDown} marginLeft="auto" />
  </div>
);

export default Placeholder;
