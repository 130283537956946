import React, { FC } from 'react';
import style from 'app/modules/mediaLibrary/components/mediaLibraryModal/albumSelect/AlbumItem.module.scss';
import { pluralize } from 'utils/lang';
import { MediaLibraryAlbum } from 'types/MediaLibrary';
import { TYPE_UNSPECIFIED } from '@kontentino/kontentino-constants/Pages';
import { SocialMediaIcon, SocialMediaIconProps } from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';

type Props = {
  album: MediaLibraryAlbum;
  onClick?: AnyFunction;
};

const AlbumItem: FC<Props> = ({ album, onClick }) => {
  return (
    <div className={style.album} onClick={onClick}>
      <SocialMediaIcon
        type={
          PageTypeUtils.getName(
            album.page?.type ?? TYPE_UNSPECIFIED,
          ) as SocialMediaIconProps['type']
        }
        size={18}
      />
      <div className={style.desc}>
        <span className={style.name}>
          {album.page?.name ? `${album.page.name} - ` : ''}
        </span>
        <span>{album.name}</span>
        <span className={style.counts}>
          {`(${pluralize(album.images_count, 'image')}, ${pluralize(
            album.videos_count,
            'video',
          )})`}
        </span>
      </div>
    </div>
  );
};

export default AlbumItem;
