import React, { FC } from 'react';
import { PostComposerProviderState } from 'app/modules/posts/modules/post/components/composer/PostComposer';

const PostComposerContext = React.createContext<
  PostComposerProviderState | undefined
>(undefined);

type PostComposerProviderProps = {
  value: PostComposerProviderState;
  children: React.ReactNode;
};

export const PostComposerProvider: FC<PostComposerProviderProps> = ({
  value,
  children,
}) => {
  return (
    <PostComposerContext.Provider value={value}>
      {children}
    </PostComposerContext.Provider>
  );
};

export const usePostComposer = () => {
  const context = React.useContext(PostComposerContext);

  if (!context) {
    throw new Error('Must be used within PostComposerProvider ');
  }

  return context;
};
