import React, { FC } from 'react';
import UISkeleton from 'react-loading-skeleton';
import style from 'app/modules/mediaLibrary/components/mediaLibraryModal/mediaLibraryItemsContainer/LibraryMedia.module.scss';
import ArrayUtils from 'app/utils/array';

type Props = {};

const skeletons = ArrayUtils.generate(24);

const Skeleton: FC<Props> = () => {
  return (
    <>
      {skeletons.map((s, i) => (
        <div className={style.libraryItem} key={i}>
          <UISkeleton height="100%" />
        </div>
      ))}
    </>
  );
};

export default Skeleton;
