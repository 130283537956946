import React, { FC, forwardRef, MouseEventHandler, ReactNode } from 'react';
import {
  Icon,
  IconButton,
  mergeTailwindClasses,
  Tooltip,
} from '@kontentino/ui';
import clsx from 'clsx';
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons/faCloudArrowUp';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faFilm } from '@fortawesome/pro-regular-svg-icons/faFilm';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import ProgressIndicator from 'app/components/ProgressIndicator';
import { useTranslation } from 'react-i18next';
type Props = {
  src?: string;
  orderIndex?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
  className?: string;
  subActionHandlers?: {
    onEditClick?: MouseEventHandler<HTMLButtonElement>;
    onDeleteClick?: MouseEventHandler<HTMLButtonElement>;
    onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
    onViewClick?: MouseEventHandler<HTMLButtonElement>;
    onReplaceClick?: MouseEventHandler<HTMLButtonElement>;
    onDownloadClick?: MouseEventHandler<HTMLButtonElement>;
    onCustomThumbnailClick?: MouseEventHandler<HTMLButtonElement>;
  };
  isLoading?: boolean;
  isError?: boolean;
  isPending?: boolean;
  typeIndicator?: ReactNode;
  loadingProgress?: number;
};

/**
 * @deprecated use `MediaPreview` from `src/app/components/compoundMediaPreview/index.ts` instead
 */
const MediaPreview: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    return (
      <div
        data-name="media-preview"
        ref={ref}
        className={mergeTailwindClasses(
          clsx(
            'tw-group tw-relative tw-h-[5.75rem] tw-w-[5.75rem] tw-rounded-md tw-bg-grayscale-180/25 tw-bg-cover tw-bg-center',
            { 'tw-cursor-pointer': props.onClick },
            props.className,
          ),
        )}
        style={props.src ? { backgroundImage: `url(${props.src})` } : undefined}
        onClick={props.onClick}
      >
        <div className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-rounded-md tw-bg-grayscale-180 tw-opacity-0 tw-transition-all group-hover:tw-opacity-25" />
        {typeof props.orderIndex === 'number' && (
          <div
            className={clsx(
              'tw-absolute tw-left-1 tw-top-1 tw-flex tw-min-h-[1rem] tw-min-w-[1rem] tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5 tw-px-1 tw-text-xs tw-font-semibold tw-text-grayscale-180',
              {
                'tw-bg-primary-100 tw-text-white': props.isSelected,
              },
            )}
          >
            {props.orderIndex}
          </div>
        )}
        {props.typeIndicator && (
          <div className="tw-absolute tw-right-1 tw-top-1">
            {props.typeIndicator}
          </div>
        )}
        {props.isSelected && (
          <div className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-rounded-md tw-border-[2px] tw-border-primary-100" />
        )}
        {props.isLoading && (
          <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-cursor-wait tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-180/25 tw-text-md tw-text-white">
            {typeof props.loadingProgress === 'number' && (
              <div>
                <ProgressIndicator
                  progress={props.loadingProgress}
                  color="white"
                />
              </div>
            )}
            <span className="tw-text-sm tw-font-medium tw-text-white">
              {t('uploading')}&hellip;
            </span>
          </div>
        )}
        {props.isError && (
          <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-100/25 tw-text-md tw-text-white">
            <span className="tw-text-sm tw-text-white">Error</span>
          </div>
        )}
        {props.isPending && (
          <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-100/25 tw-text-md tw-text-white">
            <span className="tw-text-sm tw-text-white">Pending...</span>
          </div>
        )}
        {Object.values(props.subActionHandlers ?? {}).some(
          (handler) => !!handler,
        ) && (
          <div className="tw-absolute tw-bottom-1 tw-right-1 tw-flex tw-overflow-hidden tw-rounded-md tw-opacity-0 tw-transition-all group-hover:tw-opacity-100">
            {props.subActionHandlers?.onCustomThumbnailClick && (
              <Tooltip content="Custom thumbnail">
                <IconButton
                  icon={<Icon icon={faFilm} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onCustomThumbnailClick}
                />
              </Tooltip>
            )}
            {props.subActionHandlers?.onViewClick && (
              <Tooltip content={t('preview')}>
                <IconButton
                  icon={<Icon icon={faEye} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onViewClick}
                />
              </Tooltip>
            )}
            {props.subActionHandlers?.onEditClick && (
              <Tooltip content={t('edit')}>
                <IconButton
                  icon={<Icon icon={faEdit} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onEditClick}
                />
              </Tooltip>
            )}
            {props.subActionHandlers?.onReplaceClick && (
              <Tooltip content={t('replace')}>
                <IconButton
                  icon={<Icon icon={faCloudArrowUp} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onReplaceClick}
                />
              </Tooltip>
            )}
            {props.subActionHandlers?.onDownloadClick && (
              <Tooltip content={t('download')}>
                <IconButton
                  icon={<Icon icon={faArrowDownToLine} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onDownloadClick}
                />
              </Tooltip>
            )}
            {props.subActionHandlers?.onRemoveClick && (
              <Tooltip content={t('remove')}>
                <IconButton
                  icon={<Icon icon={faXmark} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onRemoveClick}
                />
              </Tooltip>
            )}
            {props.subActionHandlers?.onDeleteClick && (
              <Tooltip content={t('delete')}>
                <IconButton
                  icon={<Icon icon={faTrash} />}
                  size="small"
                  variant="secondary"
                  className="tw-rounded-none"
                  onClick={props.subActionHandlers?.onDeleteClick}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  },
);

export default MediaPreview;
