import {
  IMAGE_FILE_EXTENSIONS,
  IMAGE_FILE_MIME_TYPES,
} from '@kontentino/kontentino-constants/Files';
import FilesUtil from 'utils/files';
import { useMutation } from 'react-query';
import { MediaApi } from 'api/media';
import { VideoThumbnail } from 'types/MediaLibrary';
import PostsConfig from 'app/config/posts';
import { useToast } from 'app/hooks/useToast';
import Logger from 'utils/logger';
import i18n from 'i18n';
import { ApiClientError } from 'api/client';
import { useFileDropzone } from 'app/hooks/useFileDropzone';

const useVideoThumbnailUploader = (props: {
  videoId?: number;
  onMediaUploaded: (thumbnail: VideoThumbnail) => void;
  thumbnailOffsetMilliseconds?: number | undefined;
}) => {
  const toast = useToast();
  const uploadThumbnail = useMutation(MediaApi.uploadVideoThumbnail, {
    onSuccess: (data) => {
      props.onMediaUploaded(data);
      toast(`Thumbnail for the video was successfully set.`, 'success');
    },
    onError: (error: ApiClientError) => {
      Logger.error(error);
      toast(error?.userMessage ?? i18n.somethingWentWrongTryAgain);
    },
  });

  const dropzone = useFileDropzone({
    onDrop: upload,
    accept: PostsConfig.FACEBOOK_VIDEO_THUMBNAIL_ACCEPT_FILES,
    maxFiles: PostsConfig.FACEBOOK_VIDEO_THUMBNAIL_MAX_UPLOAD_FILES,
    multiple: false,
  });

  function getValidFiles(files: File[]) {
    return FilesUtil.detectFiles(
      FilesUtil.filterFilesByOptions(files, {
        extensions: IMAGE_FILE_EXTENSIONS,
        mimeTypes: IMAGE_FILE_MIME_TYPES,
      }),
    );
  }

  function upload(files: File[]) {
    const validFiles = getValidFiles(files);

    if (props.videoId && validFiles.images.length) {
      uploadThumbnail.mutate({
        videoId: props.videoId,
        thumbnail: validFiles.images[0],
        thumbnailOffsetMilliseconds: props.thumbnailOffsetMilliseconds,
      });
    }
  }

  return {
    dropzone,
    upload,
    isLoading: uploadThumbnail.isLoading,
    isError: uploadThumbnail.isError,
    error: uploadThumbnail.error,
  };
};

export default useVideoThumbnailUploader;
