import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { Button, Icon, Tooltip } from '@kontentino/ui';
import { queryKey } from 'constants/queryKey';
import routes from 'constants/routes';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import AssetCard from './new/AssetCard';
import ArrayUtils from 'app/utils/array';
import Skeleton from 'react-loading-skeleton';
import Logger from 'utils/logger';
import { MediaLibraryApi } from '../api/mediaLibrary';
import SubAlbumCreateModal from './new/SubAlbumCreateModal';
import { Fragment, useState } from 'react';
import AlbumCard from './new/AlbumCard';

const AlbumDetail = () => {
  const [params] = useSearchParams();
  const albumId = params.get('id') ?? '';
  const [openSubAlbumModal, setOpenSubAlbumModal] = useState(false);

  const { data: albumData } = useQuery(
    queryKey.albumDetail(albumId),
    async () => {
      return await MediaLibraryApi.getAlbum(albumId);
    },
  );
  Logger.log('albumData', albumData);

  const skeletons = ArrayUtils.generate(6);

  const { data: assets, isLoading } = useQuery(
    queryKey.allAssets(),
    async () => {
      const response = await MediaLibraryApi.getAssets({ album_id: albumId });
      return response.data;
    },
  );

  return (
    <>
      <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-p-6">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-mb-6 tw-flex tw-items-center tw-gap-2">
            <Link
              to={routes.ALBUMS}
              className="tw-text-lg tw-font-semibold tw-text-grayscale-180 hover:tw-text-primary-100"
            >
              Albums
            </Link>

            {albumData?.breadcrumbs?.map((breadcrumb) => (
              <Fragment key={breadcrumb.id}>
                <Icon icon={faChevronRight} className="tw-text-grayscale-100" />
                <Link to={`${routes.ALBUM_DETAIL}?id=${breadcrumb.id}`}>
                  <span className="tw-text-lg tw-font-semibold tw-text-grayscale-180">
                    {breadcrumb.name}
                  </span>
                </Link>
              </Fragment>
            ))}
            <>
              <Icon icon={faChevronRight} className="tw-text-grayscale-100" />
              <Link to={`${routes.ALBUM_DETAIL}?id=${albumData?.album.id}`}>
                <h2 className="tw-text-lg tw-font-semibold tw-text-grayscale-180">
                  {albumData?.album?.name}
                </h2>
              </Link>
            </>

            {albumData?.album?.description && (
              <Tooltip
                content={
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-mb-1 tw-text-sm tw-font-semibold">
                      Description:
                    </span>
                    <p className="tw-text-sm">{albumData?.album.description}</p>
                  </div>
                }
              >
                <Icon
                  icon={faInfoCircle}
                  size={14}
                  className="tw-align-middle tw-text-grayscale-100"
                />
              </Tooltip>
            )}
          </div>
          <Button variant="primary" onClick={() => setOpenSubAlbumModal(true)}>
            + Album
          </Button>
        </div>

        {albumData?.albums && albumData.albums.length > 0 && (
          <div>
            <h2 className="tw-text-base tw-font-semibold">Folders</h2>
            <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 tw-py-6">
              {albumData.albums.map((subAlbum) => (
                <AlbumCard key={subAlbum.id} album={subAlbum} isSubAlbum />
              ))}
            </div>
          </div>
        )}

        <div className="tw-flex tw-flex-wrap tw-gap-4">
          {assets &&
            assets.length > 0 &&
            assets
              .slice(0, 4)
              .map((asset) => <AssetCard key={asset.id} asset={asset} />)}

          {isLoading &&
            skeletons.map((i) => (
              <div className="tw-mt-2" key={i}>
                <Skeleton height={228} />
              </div>
            ))}
        </div>
      </div>
      <SubAlbumCreateModal
        onClose={() => setOpenSubAlbumModal(false)}
        isOpen={openSubAlbumModal}
        parentAlbumId={albumId}
      />
    </>
  );
};

export default AlbumDetail;
