import { VideoMediumAttachmentCard } from 'types/Attachments';
import { MediaLibraryItem } from 'types/MediaLibrary';
import { useToast } from 'app/hooks/useToast';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { MediaApi } from 'api/media';
import { VIDEO_STATUS } from '@kontentino/kontentino-constants/Video';
import { processedVideoToMediaLibraryItem } from 'utils/mediaLibrary';
import { Video } from 'types/Video';
import i18n from 'i18n';

export type UseVideoMediumRefreshProps = Props;

type Props = {
  runningUploadId?: string;
  medium?: Partial<
    Pick<VideoMediumAttachmentCard, 'id' | 'status'> & {
      type: 'image' | 'video';
    }
  > | null;
  onUpdateItem?(item: MediaLibraryItem, runningUploadId?: string): void;
};

export function useVideoMediumRefresh(props: Props = {}) {
  const toast = useToast();

  useQuery(
    [queryKey.videoMedium, props.medium?.id],
    () => {
      if (props.medium?.id) {
        return MediaApi.getVideo(props.medium?.id);
      }
    },
    {
      refetchInterval: 10000,
      enabled: getIsQueryEnabled(),
      onSuccess(response) {
        if (response?.status === VIDEO_STATUS.AVAILABLE) {
          props.onUpdateItem?.(
            processedVideoToMediaLibraryItem(response as Required<Video>),
            props.runningUploadId,
          );
        }
      },
      onError() {
        toast(i18n.somethingWentWrongTryAgain, 'error');
      },
    },
  );

  function getIsQueryEnabled() {
    return (
      !!props.medium?.id &&
      props.medium.type === 'video' &&
      props.medium.status !== VIDEO_STATUS.AVAILABLE &&
      props.medium.status !== VIDEO_STATUS.ERROR
    );
  }
}
