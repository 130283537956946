import React, { FC, useEffect, useMemo, useState } from 'react';
import { usePostsContainer } from 'components/posts/context';
import dayjs, { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';
import { Button } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';
import PostsConfig from 'app/config/posts';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import DatePickerDropdown from 'app/components/datepicker-dropdown';
import DatePickerHeaderCustomPeriod from 'app/components/datepicker-dropdown/custom-header/DatePickerHeaderCustomPeriod';
import DatePickerFooter from 'app/components/datepicker-dropdown/DatePickerFooter';
import DatePickerHeaderDefault from 'app/components/datepicker-dropdown/custom-header/DatePickerHeaderDefault';
import { getMaximumPostsFilterEndDate } from 'app/modules/posts/utils/getValidPostsFilterDateRange';
import useSetState from 'utils/hooks/useSetState';
import {
  getDatesWithConstraints,
  isDateWithinRange,
} from 'app/components/datepicker-dropdown/utils';
import { getRangePlaceholderLabel } from 'app/components/datepicker-dropdown/utils/placeholder';

const MIN_DATE = dayjs()
  .subtract(PostsConfig.MIN_PREVIOUS_NUMBER_OF_YEARS, 'year')
  .startOf('year');

const MAX_MONTH_DATE_TO_SELECT = dayjs()
  .add(PostsConfig.MAX_NEXT_NUMBER_OF_YEARS, 'year')
  .endOf('year');

type Props = {
  startDate: Dayjs;
  endDate: Dayjs;
  onChange: ({
    startDate,
    endDate,
  }: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }) => void;
};

const PostsDatePicker: FC<Props> = ({ startDate, endDate, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useSetState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: true,
    endDate: true,
  });
  const [startDateTemp, setStartDateTemp] = useState<Dayjs | null>(
    startDate ?? null,
  );
  const [endDateTemp, setEndDateTemp] = useState<Dayjs | null>(endDate ?? null);

  const postsContainer = usePostsContainer();
  const { t } = useTranslation();

  const { isRangeDateViewActive, setIsRangeDateViewActive } =
    usePostsLayoutState();

  const onClearCustomPeriod = () => {
    const dates = getDatesWithConstraints(
      {
        startDate: dayjs().startOf('M'),
        endDate: dayjs().endOf('M'),
      },
      { maxEndDate: MAX_MONTH_DATE_TO_SELECT },
    );

    onChange({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    setStartDateTemp(null);
    setEndDateTemp(null);
    setIsRangeDateViewActive(false);
  };

  const onCancelCustomPeriod = () => {
    setStartDateTemp(startDate);
    setEndDateTemp(endDate);
  };

  const onDateRangeSubmit = () => {
    onChange({
      startDate: startDateTemp ?? null,
      endDate: endDateTemp ?? null,
    });
    setIsOpen(false);
  };

  const onDateSelect = (date?: Date) => {
    if (date) {
      const startDate = dayjs(date);
      const endDate = dayjs(date);

      if (startDate && endDate) {
        onChange({
          startDate: startDate.startOf('M'),
          endDate: endDate.endOf('M'),
        });
        setIsOpen(false);
      }
    }
  };

  const onRangeSelect = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDateTemp(start ? dayjs(start) : null);
    setEndDateTemp(end ? dayjs(end) : null);
  };

  const label = useMemo(() => {
    if (isRangeDateViewActive) {
      return getRangePlaceholderLabel({
        startDateTemp,
        endDateTemp,
      });
    }

    return DateUtils.toMonthYearString(startDate ?? null);
  }, [startDate, startDateTemp, endDateTemp, isRangeDateViewActive]);

  const selectedDate = useMemo(
    () => (isRangeDateViewActive ? null : startDate ?? null),
    [startDate, isRangeDateViewActive],
  );

  const maxDate = useMemo(
    () =>
      startDateTemp
        ? getMaximumPostsFilterEndDate(startDateTemp)
        : MAX_MONTH_DATE_TO_SELECT,
    [startDateTemp],
  );

  useEffectOnce(() => {
    setIsRangeDateViewActive(
      !DateUtils.isDateRangeMatchesMonth(
        postsContainer.filterState.startDate,
        postsContainer.filterState.endDate,
      ),
    );
  });

  useEffect(() => {
    if (MIN_DATE && maxDate && startDate && endDate) {
      setError({
        startDate: startDate
          ? isDateWithinRange(startDate, MIN_DATE, dayjs(maxDate))
          : false,
        endDate: endDate
          ? isDateWithinRange(endDate, MIN_DATE, dayjs(maxDate))
          : false,
      });
    }
  }, [startDate, endDate, maxDate, setError]);

  return (
    <DatePickerDropdown
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      label={label}
      selected={selectedDate?.toDate() ?? null}
      startDate={startDateTemp?.toDate() ?? null}
      endDate={endDateTemp?.toDate() ?? null}
      selectsRange={isRangeDateViewActive}
      showMonthYearPicker={!isRangeDateViewActive}
      dateFormat={!isRangeDateViewActive ? 'MM/yyyy' : undefined}
      onChange={(val) => {
        isRangeDateViewActive
          ? onRangeSelect(val as [Date | null, Date | null])
          : onDateSelect(val as Date);
      }}
      renderCustomHeader={(headerProps) =>
        isRangeDateViewActive ? (
          <DatePickerHeaderCustomPeriod
            {...headerProps}
            minDate={MIN_DATE}
            maxDate={dayjs(maxDate)}
            error={error}
            startDate={startDateTemp ?? startDate}
            endDate={endDateTemp ?? endDate}
            onStartDateChange={(date) => setStartDateTemp(date)}
            onEndDateChange={(date) => setEndDateTemp(date)}
          />
        ) : (
          <DatePickerHeaderDefault
            {...headerProps}
            minDate={MIN_DATE}
            maxDate={MAX_MONTH_DATE_TO_SELECT}
          />
        )
      }
    >
      {!isRangeDateViewActive && (
        <Button
          variant={'ghost'}
          onClick={() => setIsRangeDateViewActive(true)}
          className="tw-flex-0 tw-m-1 tw-mr-auto tw-h-min !tw-p-2"
        >
          {t('customPeriod')}
        </Button>
      )}
      {isRangeDateViewActive && (
        <DatePickerFooter
          isCancelDisabled={
            startDate === startDateTemp && endDate === endDateTemp
          }
          onDateRangeSubmit={onDateRangeSubmit}
          onRangeSelectCancel={onCancelCustomPeriod}
          onClearCustomPeriod={onClearCustomPeriod}
        />
      )}
    </DatePickerDropdown>
  );
};

export default PostsDatePicker;
