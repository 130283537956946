import { Field, TextArea, TextInput } from '@kontentino/ui';
import { usePage } from 'modules/page/pageSelector';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AlbumFormValues } from './albumFormSchema';
import AlbumPageTypeSelect from './AlbumPageTypeSelect';

type Props = {
  isSubAlbum?: boolean;
  initialData?: AlbumFormValues;
  hideDescriptionInput?: boolean;
};

const AlbumForm: FC<Props> = ({
  isSubAlbum = false,
  initialData,
  hideDescriptionInput = false,
}) => {
  const {
    control,
    register,
    setValue,

    formState: { errors },
  } = useFormContext<AlbumFormValues>();

  const { pages, status: pagesStatus } = usePage();

  const initialAssignedModelId = initialData?.assigned_model_id;
  const initialAssignedModelType = initialData?.assigned_model_type;
  const initialOption = pages.find(
    (page) =>
      page.id.toString() === initialAssignedModelId &&
      page.type.toString() === initialAssignedModelType,
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-2">
      <Field.Group>
        <Field.Label required>Name</Field.Label>
        <TextInput
          {...register('name', {
            validate: (value) => value.trim().length > 0 || 'Name is required',
          })}
          required
          placeholder="Name"
          data-cy="create-album_name"
        />
        <Field.Error>{errors.name?.message}</Field.Error>
      </Field.Group>
      {!hideDescriptionInput && (
        <Field.Group>
          <Field.Label>Description</Field.Label>
          <TextArea
            {...register('description')}
            placeholder="Description"
            data-cy="create-album_description"
          />
        </Field.Group>
      )}

      {!isSubAlbum && (
        <Field.Group>
          <Field.Label required>Assign to profile</Field.Label>
          <Controller
            disabled={pagesStatus === 'loading'}
            name="assigned_model_id"
            control={control}
            defaultValue={initialOption?.id.toString() || undefined}
            render={({ field: { onChange, value } }) => (
              <AlbumPageTypeSelect
                isDisabled={pagesStatus === 'loading'}
                pages={pages}
                onSelect={(option) => {
                  onChange(option);
                  setValue('assigned_model_id', option?.id.toString() ?? '');
                  setValue('assigned_model_name', 'Page');
                  setValue(
                    'assigned_model_type',
                    option?.type.toString() ?? '',
                  );
                }}
                value={value}
              />
            )}
          />
          {hideDescriptionInput && (
            <span className="tw-text-md tw-font-regular tw-text-grayscale-100">
              All uploaded assets will be stored in this profile.
            </span>
          )}
        </Field.Group>
      )}
    </div>
  );
};

export default AlbumForm;
