import { ImageTemplate } from 'types/ImageTemplates';

const ImageTemplatesUtil = {
  formatToWatermarks(templates: ImageTemplate | ImageTemplate[]) {
    const imageTemplates = Array.isArray(templates) ? templates : [templates];

    return imageTemplates.map((template) => ({
      url: template.media.lightbox.src,
      label: template.name,
    }));
  },
};

export default ImageTemplatesUtil;
