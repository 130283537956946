import React, { FC, MouseEventHandler, useMemo, useRef } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { RunningUpload } from 'types/RunningUpload';
import RunningUploadsItems from 'app/modules/mediaLibrary/components/mediaLibraryModal/mediaLibraryItemsContainer/RunningUploadsItems';
import AttachmentsCarousel from 'app/components/mediaAttachments/components/AttachmentsCarousel';
import {
  AttachmentCardMedium,
  AttachmentCards,
  VideoMediumAttachmentCard,
} from 'types/Attachments';
import {
  AttachmentConvertor,
  getAttachmentCardThumbnail,
  getAttachmentsCardsSources,
  isImageAttachmentCard,
} from 'utils/attachments';
import { VIDEO_STATUS_AVAILABLE } from '@kontentino/kontentino-constants/Video';
import { LightBox, LightBoxInstance } from '@kontentino/ui';
import {
  useVideoMediumRefresh,
  UseVideoMediumRefreshProps,
} from 'app/components/mediaAttachments/hooks/useVideoMediumRefresh';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import MediaPreview from 'app/components/compoundMediaPreview';
import clsx from 'clsx';
import { ImageEditorInput } from 'app/components/imageEditor/hooks/useImageEditor';
import MediaPreviewTypeIndicator from 'app/modules/posts/components/MediaPreviewTypeIndicator';
import { lockAxisInTransformStyle } from 'app/modules/posts/utils/lockTransformAxisStyle';

type Props = {
  attachmentsCards: AttachmentCards;
  onRemoveAttachmentAtIndex(index: number): void;
  onCustomThumbnailClick?: (index: VideoMediumAttachmentCard | null) => void;
  onAttachmentIndexMove(oldIndex: number, newIndex: number): void;
  runningUploads: RunningUpload[];
  onEditImage(image: ImageEditorInput): void;
  updateAttachmentMedium(
    medium: AttachmentCardMedium,
    runningUploadId?: string,
  ): void;
};

const SelectedAttachments: FC<Props> = (props) => {
  const lightbox = useRef<LightBoxInstance>();

  function onDragEnd(result: DropResult) {
    if (result.destination) {
      props.onAttachmentIndexMove(
        result.source.index,
        result.destination.index,
      );
    }
  }

  const Attachment: FC<
    {
      orderIndex?: number;
      src?: string;
      subActionHandlers?: {
        onCustomThumbnailClick?: MouseEventHandler<HTMLButtonElement>;
        onEditClick?: MouseEventHandler<HTMLButtonElement>;
        onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
        onViewClick?: MouseEventHandler<HTMLButtonElement>;
      };
      typeIndicator: React.ReactNode;
    } & {
      videoRefreshConfig: UseVideoMediumRefreshProps;
    }
  > = ({ videoRefreshConfig, ...restProps }) => {
    useVideoMediumRefresh(videoRefreshConfig);

    return (
      <UserPermissionGate
        scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}
        deniedProps={{
          onDeleteClick: undefined,
          onEditClick: undefined,
        }}
      >
        <MediaPreview.Root>
          <MediaPreview.Image src={restProps.src} />
          <MediaPreview.Overlay />
          <MediaPreview.Actions>
            {restProps.subActionHandlers?.onCustomThumbnailClick && (
              <MediaPreview.ActionButton
                actionType="customThumbnail"
                onClick={restProps.subActionHandlers.onCustomThumbnailClick}
              />
            )}
            {restProps.subActionHandlers?.onViewClick && (
              <MediaPreview.ActionButton
                actionType="preview"
                onClick={restProps.subActionHandlers.onViewClick}
              />
            )}
            {restProps.subActionHandlers?.onEditClick && (
              <MediaPreview.ActionButton
                actionType="edit"
                onClick={restProps.subActionHandlers.onEditClick}
              />
            )}
            {restProps.subActionHandlers?.onRemoveClick && (
              <MediaPreview.ActionButton
                actionType="delete"
                onClick={restProps.subActionHandlers.onRemoveClick}
              />
            )}
          </MediaPreview.Actions>
        </MediaPreview.Root>
      </UserPermissionGate>
    );
  };

  return (
    <AttachmentsCarousel
      itemsCount={props.attachmentsCards.length + props.runningUploads.length}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={clsx('tw-flex tw-gap-2')}
              {...provided.droppableProps}
            >
              {props.attachmentsCards.map((attachment, index) => (
                <Draggable
                  draggableId={`draggable-attachment-${index}`}
                  key={`draggable-attachment-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={lockAxisInTransformStyle(
                        'X',
                        provided.draggableProps.style,
                      )}
                    >
                      <Attachment
                        orderIndex={
                          props.attachmentsCards.length > 1
                            ? index + 1
                            : undefined
                        }
                        subActionHandlers={{
                          onCustomThumbnailClick: props.onCustomThumbnailClick
                            ? () =>
                                props.onCustomThumbnailClick?.(
                                  attachment.medium?.type === 'video'
                                    ? attachment.medium
                                    : null,
                                )
                            : undefined,
                          onRemoveClick: () =>
                            props.onRemoveAttachmentAtIndex(index),
                          onEditClick: isImageAttachmentCard(attachment)
                            ? () =>
                                props.onEditImage({
                                  src: attachment.medium.sizes.original.src,
                                  index,
                                })
                            : undefined,
                          onViewClick:
                            attachment.medium?.type === 'video' &&
                            attachment.medium.status !== VIDEO_STATUS_AVAILABLE
                              ? undefined
                              : () => lightbox.current?.openGallery(index),
                        }}
                        typeIndicator={
                          attachment?.medium && (
                            <MediaPreviewTypeIndicator
                              medium={AttachmentConvertor.toMediaLibraryItem(
                                attachment.medium,
                              )}
                            />
                          )
                        }
                        key={`attachment-${index}`}
                        src={getAttachmentCardThumbnail(attachment)}
                        videoRefreshConfig={{
                          medium: attachment.medium,
                          onUpdateItem: (video) =>
                            props.updateAttachmentMedium?.(
                              AttachmentConvertor.fromMediaLibraryItem(video)
                                .medium,
                            ),
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <RunningUploadsItems runningUploads={props.runningUploads} />
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {useMemo(
        () => (
          <LightBox
            dynamic
            dynamicEl={getAttachmentsCardsSources(props.attachmentsCards)}
            galleryId="attachments-lightbox"
            onInit={({ instance }) => (lightbox.current = instance)}
          />
        ),
        [props.attachmentsCards],
      )}
    </AttachmentsCarousel>
  );
};

export default SelectedAttachments;
