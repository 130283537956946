import React, { ComponentProps, FC } from 'react';
import style from 'app/modules/mediaLibrary/components/mediaLibraryModal/mediaLibraryItemsContainer/LibraryMedia.module.scss';
import {
  MediaLibraryItem as MediaAttachmentType,
  MediaLibraryItems as MediaLibraryItemsType,
} from 'types/MediaLibrary';

import { getSelectedOrderIndex } from 'utils/attachments';
import MediaPreview from 'app/components/MediaPreview';
import {
  useVideoMediumRefresh,
  UseVideoMediumRefreshProps,
} from 'app/components/mediaAttachments/hooks/useVideoMediumRefresh';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { ImageEditorInput } from 'app/components/imageEditor/hooks/useImageEditor';
import MediaPreviewTypeIndicator from 'app/modules/posts/components/MediaPreviewTypeIndicator';

type Props = {
  media: MediaLibraryItemsType;
  selectedMediaIds: number[];
  onToggleSelectedMedium(medium: MediaAttachmentType): void;
  onDeleteMedium(medium: MediaAttachmentType): void;
  onEditImage(image: ImageEditorInput): void;
};

const MediaLibraryItems: FC<Props> = (props) => {
  return (
    <>
      {props.media.map((medium, i) => {
        const isSelected = props.selectedMediaIds.includes(medium.id);

        return (
          <MediaLibraryItem
            className={style.libraryItem}
            typeIndicator={<MediaPreviewTypeIndicator medium={medium} />}
            orderIndex={getSelectedOrderIndex(props.selectedMediaIds, medium)}
            key={`${medium.id}-${i}`}
            src={medium.data?.thumbnail?.src}
            isSelected={isSelected}
            onClick={() => props.onToggleSelectedMedium(medium)}
            subActionHandlers={{
              onDeleteClick:
                !isSelected && medium.type === 'image'
                  ? () => props.onDeleteMedium(medium)
                  : undefined,
              onEditClick:
                medium.type === 'image'
                  ? () => props.onEditImage({ src: medium.data.src })
                  : undefined,
            }}
          />
        );
      })}
    </>
  );
};

const MediaLibraryItem: FC<
  ComponentProps<typeof MediaPreview> & {
    videoRefreshConfig?: UseVideoMediumRefreshProps;
  }
> = (props) => {
  useVideoMediumRefresh(props.videoRefreshConfig);

  return (
    <UserPermissionGate
      scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}
      deniedProps={{
        onDeleteClick: undefined,
        onEditClick: undefined,
      }}
    >
      <MediaPreview {...props} />
    </UserPermissionGate>
  );
};

export default MediaLibraryItems;
