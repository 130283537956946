import {
  RunningUploadsJob,
  RunningUploadsRecord,
  UploadSequence,
} from 'types/RunningUpload';
import { createRunningUploadsFromFiles } from 'app/utils/runningUploads/runningUploadsFiles';

export type CreateRunningUploadsParams = {
  upload: {
    files: { videos: File[]; images: File[] };
  };
  options: {
    pageId: number;
    albumId?: number;
    userAccountPublicId: string;
    userId: number;
    uploadSequence: UploadSequence;
  };
};

export type CreateRunningUploadsReturn = {
  runningUploads: RunningUploadsRecord;
  jobs: RunningUploadsJob[];
};

export async function createRunningUploads({
  upload,
  options,
}: CreateRunningUploadsParams): Promise<CreateRunningUploadsReturn> {
  let runningUploads: RunningUploadsRecord = {};
  let jobs: RunningUploadsJob[] = [];

  function appendUploads(newUploads: CreateRunningUploadsReturn) {
    runningUploads = {
      ...runningUploads,
      ...newUploads.runningUploads,
    };

    jobs = [...jobs, ...newUploads.jobs];
  }

  if (upload.files) {
    const fileRunningUploads = await createRunningUploadsFromFiles({
      files: upload.files,
      ...options,
    });

    appendUploads(fileRunningUploads);
  }

  return {
    runningUploads,
    jobs,
  };
}
