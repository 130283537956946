import TimeUtils from 'app/utils/time';
import { InputRange, InputRangeProps } from '@kontentino/ui';
import React, { FC } from 'react';

type Props = {
  value: number;
  onChange: InputRangeProps['onChange'];
  duration: number;
};

const VideoSlider: FC<Props> = ({ value, onChange, duration }) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-5">
      <span className="tw-select-none tw-text-sm tw-font-semibold">
        {TimeUtils.calculateDurationFromMilliseconds(
          TimeUtils.calcMilliseconds.seconds(value),
        )}
      </span>
      <InputRange
        min={0}
        max={duration}
        step={0.1}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default VideoSlider;
