import React, { FC } from 'react';
import i18n from 'i18n';
import style from 'app/modules/mediaLibrary/components/mediaLibraryModal/footer/Footer.module.scss';
import { Button } from '@kontentino/ui';
import Modal from 'components/shared/Modal';

type Props = {
  onSelectSubmit: () => void;
  onCancel: () => void;
  selectedCount: number;
};

const Footer: FC<Props> = (props) => {
  return (
    <Modal.Footer>
      <div className={style.dragImagesLabel}>
        Drag images and videos anywhere to upload
      </div>
      <Button
        onClick={props.onCancel}
        variant="secondary"
        data-cy="media-library_cancel-button"
      >
        {i18n.cancel}
      </Button>
      <Button
        onClick={props.onSelectSubmit}
        disabled={props.selectedCount === 0}
        data-cy="media-library_select-button"
      >
        {i18n.select}{' '}
        {props.selectedCount > 1 && `${props.selectedCount} media`}
      </Button>
    </Modal.Footer>
  );
};

export default Footer;
