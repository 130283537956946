import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@kontentino/ui';
import { ApiClientError } from 'api/client';
import { useToast } from 'app/hooks/useToast';
import Modal from 'components/shared/Modal';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import { MediaLibraryApi } from '../../api/mediaLibrary';
import AlbumForm from './form/AlbumForm';
import {
  albumFormValidationSchema,
  AlbumFormValues,
} from './form/albumFormSchema';

interface AlbumCreateModalProps {
  onClose: () => void;
  isOpen: boolean;
  albumId: string;
  initialData: AlbumFormValues;
}

const AlbumDuplicateModal: React.FC<AlbumCreateModalProps> = ({
  onClose,
  isOpen,
  albumId,
  initialData,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const methods = useForm<AlbumFormValues>({
    resolver: zodResolver(albumFormValidationSchema),
    defaultValues: initialData,
  });

  const { handleSubmit } = methods;
  const mutation = useMutation(MediaLibraryApi.duplicateAlbum, {
    onSuccess: () => {
      queryClient.invalidateQueries('allAlbums');
      toast('Changes to the album have been saved.', 'success');
      onClose();
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
  });

  function handleSubmitData(data: AlbumFormValues) {
    const { name, ...restData } = data;
    const mutationData = {
      id: albumId,
      data: { name, ...restData },
    };
    mutation.mutate(mutationData);
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header title="Duplicate album" />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Modal.Content className="tw-flex tw-flex-col tw-gap-4 tw-pb-2">
            <AlbumForm initialData={initialData} hideDescriptionInput />
          </Modal.Content>
          <Modal.Footer withDivider>
            <Button
              variant="primary"
              type="submit"
              data-cy="album-create_save-button"
              data-name="album-create_save-button"
              className="tw-w-full"
            >
              Duplicate
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AlbumDuplicateModal;
