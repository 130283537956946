import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@kontentino/ui';
import { ApiClientError } from 'api/client';
import { useToast } from 'app/hooks/useToast';
import Modal from 'components/shared/Modal';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import AlbumForm from './form/AlbumForm';
import { MediaLibraryApi } from '../../api/mediaLibrary';
import {
  albumFormValidationSchema,
  AlbumFormValues,
} from './form/albumFormSchema';

interface AlbumEditModalProps {
  onClose: () => void;
  isOpen: boolean;
  initialData: AlbumFormValues;
  albumId: string;
}

const AlbumEditModal: React.FC<AlbumEditModalProps> = ({
  onClose,
  isOpen,
  initialData,
  albumId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(MediaLibraryApi.updateAlbum, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries('allAlbums');
      toast('Changes to the album have been saved.', 'success');
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
  });

  function handleSubmitData(data: AlbumFormValues) {
    const updatedData = { ...data };
    mutation.mutate({ id: albumId, data: updatedData });
  }

  const formMethods = useForm<AlbumFormValues>({
    resolver: zodResolver(albumFormValidationSchema),
    defaultValues: initialData,
  });

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header title="Edit album" />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmitData)}>
          <Modal.Content>
            <AlbumForm />
          </Modal.Content>
          <Modal.Footer withDivider>
            <Button
              type="submit"
              className="tw-w-full"
              variant="primary"
              data-cy="album-edit_save-button"
              data-name="album-edit_save-button"
            >
              Save
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AlbumEditModal;
