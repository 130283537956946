import React, { Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';
import MediaLibrary from './components/MediaLibrary';
import Albums from './components/Albums';
import AlbumDetail from './components/AlbumDetail';

export const mediaLibraryRouter: RouteObject = {
  path: routeConfig.mediaLibrary.path,
  element: (
    <Suspense fallback={<Skeleton width="100%" height="100%" />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      //   element: <MediaLibraryList />,
      element: <MediaLibrary />,
    },
    {
      path: routeConfig.mediaLibrary.routes.albums.path,
      element: <Albums />,
    },
    {
      path: routeConfig.mediaLibrary.routes.album.path,
      element: <AlbumDetail />,
    },
  ],
};
