import { MediaAttachmentsProps } from 'app/components/MediaAttachments';
import { useImageEditor } from 'app/components/imageEditor/hooks/useImageEditor';
import { AttachmentConvertor } from 'utils/attachments';
import {
  AttachmentCard,
  AttachmentCardMedium,
  AttachmentCards,
} from 'types/Attachments';
import ArrayUtils from 'app/utils/array';
import { MediaLibraryItem, MediaLibraryItemFileType } from 'types/MediaLibrary';
import { useFileSource } from 'app/components/fileSourceInput/hooks/useFileSource';

type Props = Omit<MediaAttachmentsProps, 'allowedFiles'> & {
  allowedFiles: MediaLibraryItemFileType[];
  maxCount: number;
};

export function useMediaAttachmentsContainer({
  attachments,
  onAttachmentsChange,
  page,
  maxCount,
  allowedFiles,
}: Props) {
  const { mediaUploader, mediaLibrary, fileSources } = useFileSource({
    page,
    onChange: onMediaUploaded,
    allowedFiles,
    maxFiles: maxCount - attachments.cards.length,
    uploadSequence: 'fifo',
  });

  const imageEditor = useImageEditor();

  function onMediaUploaded([mediaLibraryItem]: MediaLibraryItem[]) {
    if (mediaLibraryItem) {
      addAttachments(
        AttachmentConvertor.fromMediaLibraryItem(mediaLibraryItem),
      );
    }
  }

  function addAttachments(attachments: AttachmentCards | AttachmentCard) {
    onAttachmentsChange((prev) => ({
      cards: Array.isArray(attachments)
        ? [...prev.cards, ...attachments]
        : [...prev.cards, attachments],
    }));
  }

  function removeAttachmentAtIndex(index: number) {
    onAttachmentsChange((prev) => ({
      cards: prev.cards.filter((a, i) => i !== index),
    }));
  }

  function moveAttachmentIndex(oldIndex: number, newIndex: number) {
    onAttachmentsChange((prev) => ({
      cards: ArrayUtils.move(prev.cards, oldIndex, newIndex),
    }));
  }

  function updateAttachmentMedium(newMedium: AttachmentCardMedium) {
    onAttachmentsChange((prev) => ({
      cards: prev.cards.map((attachment) =>
        attachment.medium?.id === newMedium?.id
          ? { ...attachment, medium: newMedium }
          : attachment,
      ),
    }));
  }

  return {
    addAttachments,
    updateAttachmentMedium,
    removeAttachmentAtIndex,
    attachments,
    moveAttachmentIndex,
    mediaUploader,
    mediaLibrary,
    fileSources,
    totalAttachmentsLength:
      attachments.cards.length + mediaUploader.runningUploads.length,
    ...imageEditor,
  };
}
