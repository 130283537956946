import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import PageApi from 'api/page';

export const useDefaultPageAlbumId = (pageId: number) => {
  const { data } = useQuery(queryKey.pageDetail(pageId), () =>
    PageApi.detail(pageId),
  );

  return data?.defaultAlbum ?? undefined;
};
