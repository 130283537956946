import { ImageTemplate, ImageTemplates } from 'types/ImageTemplates';
import { get } from 'api/client';

export const ImageTemplatesApi = {
  get(pageId: number): Promise<ImageTemplates> {
    return get<Record<string, ImageTemplate>>('/pages/ajaxGetImageTemplates', {
      params: { pageId },
    }).then((res) =>
      Object.values(res).map((template) => ({
        ...template,
        media: {
          thumb: {
            ...template.media.thumb,
            src: template.media.thumb.webpath,
          },
          original: {
            ...template.media.original,
            src: template.media.original.webpath,
          },
          lightbox: {
            ...template.media.lightbox,
            src: template.media.lightbox.webpath,
          },
        },
      })),
    );
  },
};
