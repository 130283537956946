import React, { ComponentProps, FC } from 'react';
import { RunningUpload } from 'types/RunningUpload';
import { RunningUploadStatus } from 'constants/runningUpload';
import {
  MediaLibraryItem as MediaAttachmentType,
  MediaLibraryItem as MediaLibraryItemType,
} from 'types/MediaLibrary';
import { getSelectedOrderIndex } from 'utils/attachments';
import MediaPreview from 'app/components/MediaPreview';
import {
  useVideoMediumRefresh,
  UseVideoMediumRefreshProps,
} from 'app/components/mediaAttachments/hooks/useVideoMediumRefresh';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { ImageEditorInput } from 'app/components/imageEditor/hooks/useImageEditor';
import MediaPreviewTypeIndicator from 'app/modules/posts/components/MediaPreviewTypeIndicator';

type Props = {
  runningUploads?: RunningUpload[];
  selectedMediaIds?: number[];
  onToggleSelectedMedium?(medium: MediaLibraryItemType): void;
  itemClassName?: string;
  onEditImage?(image: ImageEditorInput): void;
  onDeleteMedium?(medium: MediaAttachmentType): void;
};

const RunningUploadsItems: FC<Props> = ({
  runningUploads = [],
  selectedMediaIds = [],
  onToggleSelectedMedium,
  itemClassName,
  onEditImage,
  onDeleteMedium,
}) => {
  return (
    <>
      {runningUploads.map(
        ({ result, status, base64Preview, id, progress }, i) => {
          const isSelected = selectedMediaIds.includes(result?.id ?? -1);

          return (
            <MediaLibraryItem
              isLoading={status === RunningUploadStatus.Uploading}
              isError={status === RunningUploadStatus.Failed}
              isPending={status === RunningUploadStatus.Pending}
              isSelected={isSelected}
              className={itemClassName}
              typeIndicator={
                result ? <MediaPreviewTypeIndicator medium={result} /> : null
              }
              orderIndex={
                result
                  ? getSelectedOrderIndex(selectedMediaIds, result)
                  : undefined
              }
              key={`${id}-${i}`}
              src={result?.data?.thumbnail?.src ?? base64Preview}
              onClick={
                result && onToggleSelectedMedium
                  ? () => onToggleSelectedMedium(result)
                  : undefined
              }
              loadingProgress={progress}
              subActionHandlers={{
                onEditClick:
                  result?.type === 'image' && onEditImage
                    ? () => onEditImage({ src: result.data.original.src })
                    : undefined,
                onDeleteClick:
                  result?.type === 'image' && !isSelected && onDeleteMedium
                    ? () => onDeleteMedium(result)
                    : undefined,
              }}
            />
          );
        },
      )}
    </>
  );
};

const MediaLibraryItem: FC<
  ComponentProps<typeof MediaPreview> & {
    videoRefreshConfig?: UseVideoMediumRefreshProps;
  }
> = (props) => {
  useVideoMediumRefresh(props.videoRefreshConfig);

  return (
    <UserPermissionGate
      scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}
      deniedProps={{
        onDeleteClick: undefined,
        onEditClick: undefined,
      }}
    >
      <MediaPreview {...props} />
    </UserPermissionGate>
  );
};

export default RunningUploadsItems;
