import { CustomRouteConfig } from 'app/types/routes';

const routeConfig = {
  public: {
    path: 'public',
    routes: {
      login: {
        path: 'login',
      },
      forgotPassword: {
        path: 'forgot-password',
        routes: {
          submitEmail: {
            path: 'submit-email',
          },
          submitCode: {
            path: 'submit-code',
          },
          setPassword: {
            path: 'set-password',
          },
        },
      },
      reports: {
        path: 'reports',
      },
      pdfReports: {
        path: 'pdf-reports',
        routes: {
          insightsPagePerformance: {
            path: 'insights-page-performance',
            routes: {
              overview: {
                path: 'overview',
              },
              currentSelection: {
                path: 'current-selection',
              },
            },
          },
          insightsPostsPerformance: {
            path: 'insights-posts-performance',
          },
          insightsCompetitionAnalysis: {
            path: 'insights-competition-analysis',
          },
        },
      },
      resetPassword: {
        path: 'reset-password',
      },
      post: {
        path: 'post',
      },
    },
  },
  dashboard: {
    path: 'dashboard',
  },
  posts: {
    path: 'posts',
    routes: {
      calendar: {
        path: 'calendar',
      },
      kanban: {
        path: 'kanban',
      },
      grid: {
        path: 'grid',
      },
      list: {
        path: 'list',
      },
    },
  },
  insights: {
    path: 'insights',
    routes: {
      postsPerformance: {
        path: 'posts-performance',
      },
      pagePerformance: {
        path: 'page-performance',
      },
      competitionAnalysis: {
        path: 'competition-analysis',
      },
    },
  },
  insightsLite: {
    path: 'insights-lite',
  },
  notifications: {
    path: 'notifications',
    routes: {
      history: {
        path: 'history',
      },
    },
  },
  adsPlanner: {
    path: 'adsplanner',
    routes: {
      list: {
        path: 'list',
      },
      timeline: {
        path: 'timeline',
      },
    },
  },
  aiContent: {
    path: 'ai-content',
  },
  brandHub: {
    path: 'brand-hub',
  },
  mediaLibrary: {
    path: 'media',
    routes: {
      albums: {
        path: 'albums',
      },
      album: {
        path: 'album',
      },
    },
  },
} satisfies CustomRouteConfig;

export default routeConfig;
