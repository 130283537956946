import React, { FC } from 'react';
import { Select } from '@kontentino/ui';
import { PageData } from 'types/Page';
import { useTranslation } from 'react-i18next';
import { BrandLogo } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import PageTypeUtils from 'app/utils/pageType';
import Logger from 'utils/logger';

interface Props {
  pages: PageData[];
  onSelect: (data: { id: string; type: string; model: string } | null) => void;
  value: string | undefined;
  isDisabled?: boolean;
}

const AlbumPageTypeSelect: FC<Props> = ({
  value,
  pages,
  onSelect,
  isDisabled,
}) => {
  const { t } = useTranslation();

  type SelectOption = PageData & {
    model: string;
  };

  const handleChange = (option: SelectOption | null) => {
    if (option) {
      onSelect({
        id: option.id.toString(),
        type: option.type.toString(),
        model: 'Page',
      });
    } else {
      onSelect(null);
    }
  };

  const selectedOption = value
    ? pages
        .map((page) => ({ ...page, model: 'Page' }))
        .find((page) => page.id.toString() === value) || null
    : null;

  return (
    <Select
      isDisabled={isDisabled}
      value={selectedOption}
      onChange={handleChange}
      isSearchable={false}
      options={pages.map((option: PageData) => ({
        ...option,
        model: 'Page',
      }))}
      placeholder={t('selectProfile')}
      menuPortalTarget={document.body}
      formatOptionLabel={(option: SelectOption) => {
        Logger.log('formatOptionLabel', option);
        return (
          <div
            className="tw-flex tw-items-center tw-gap-2"
            data-cy={`profile-select-option-${PageTypeUtils.getLabel(
              option.type,
            ).toLowerCase()}`}
          >
            <BrandLogo
              src={option.logo?.src ?? undefined}
              name={option.name}
              size={24}
              socialMediaType={getSocialMediaTypeForBrandLogo(option.type)}
              iconSize={12}
            />
            {option.name}
          </div>
        );
      }}
      styles={{
        menuPortal: (styles) => ({
          ...styles,
          zIndex: 1010,
        }),
      }}
      isOptionSelected={(option) => option.id.toString() === value}
    />
  );
};

export default AlbumPageTypeSelect;
