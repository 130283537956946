import { MediaLibraryAlbum, MediaLibraryItem } from 'types/MediaLibrary';
import { queryKey } from 'constants/queryKey';
import { useQueryClient } from 'react-query';

type InputPrams = {
  albumId: number | undefined;
  medium: MediaLibraryItem;
};

export const useAlbumsMediaCountOptimisticUpdate = () => {
  const queryClient = useQueryClient();

  const handleUpdateAlbumsQueryData = (
    albumId: number | undefined,
    updater: (album: MediaLibraryAlbum) => MediaLibraryAlbum,
  ) => {
    queryClient.setQueryData<MediaLibraryAlbum[] | undefined>(
      queryKey.allMediaAlbums,
      (prevData) => {
        if (prevData) {
          const albumIndex = prevData.findIndex(
            (album) => album.id === albumId,
          );

          const updatedAlbum = updater(prevData[albumIndex]);

          if (albumIndex !== -1) {
            const newData = [...prevData];
            newData[albumIndex] = updatedAlbum;

            return newData;
          }
        }

        return prevData;
      },
    );
  };

  return {
    increaseMediaCount: (params: InputPrams) => {
      handleUpdateAlbumsQueryData(params.albumId, (album) => ({
        ...album,
        media_count: album.media_count + 1,
        images_count:
          album.images_count + (params.medium.type === 'image' ? 1 : 0),
        videos_count:
          album.videos_count + (params.medium.type === 'video' ? 1 : 0),
      }));
    },
    decreaseMediaCount: (params: InputPrams) => {
      handleUpdateAlbumsQueryData(params.albumId, (album) => ({
        ...album,
        media_count: album.media_count - 1,
        images_count:
          album.images_count - (params.medium.type === 'image' ? 1 : 0),
        videos_count:
          album.videos_count - (params.medium.type === 'video' ? 1 : 0),
      }));
    },
  };
};
