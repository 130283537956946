import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Field, Select } from '@kontentino/ui';
import MediaAttachments from 'app/components/MediaAttachments';
import Modal from 'components/shared/Modal';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Logger from 'utils/logger';
import { z } from 'zod';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const formSchema = z.object({
  assigned_album: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

const AssetCreateModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      assigned_album: '',
    },
  });

  return (
    <FormProvider {...formMethods}>
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Header title="Add asset" />
        <Modal.Content>
          <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-2">
            <Field.Group>
              <Field.Label required>Album</Field.Label>
              <Controller
                name="assigned_album"
                control={formMethods.control}
                render={({ field: { onChange } }) => (
                  <Select
                    // isDisabled={status === 'loading'}
                    onChange={onChange}
                    // options={}
                    placeholder="Select album?"
                    menuPortalTarget={document.body}
                    // formatOptionLabel={(option: selectOption) => (
                    // )}
                    styles={{
                      menuPortal: (styles) => ({
                        ...styles,
                        zIndex: 1010,
                      }),
                    }}
                  />
                )}
              />
            </Field.Group>
            <Field.Group>
              <Field.Label required>Upload asset</Field.Label>
              <UserPermissionGate
                scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}
                deniedProps={{ disabled: true }}
              >
                <MediaAttachments
                  disabled={true}
                  page={{
                    type: 2,
                    id: 0,
                  }}
                  maxCount={1}
                  allowedFiles={['image', 'video']}
                  onAttachmentsChange={(updater) =>
                    Logger.log('Form Data Asset:', updater)
                  }
                  attachments={{
                    cards: [],
                    multi_share_end_card: false,
                  }}
                />
              </UserPermissionGate>
            </Field.Group>
          </div>
        </Modal.Content>
        <Modal.Footer withDivider>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="secondary"
            onClick={formMethods.handleSubmit((data) => {
              Logger.log('Form Data Asset:', data);
            })}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};

export default AssetCreateModal;
