import Modal from 'components/shared/Modal';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Alert, Button } from '@kontentino/ui';
import { VideoMediumAttachmentCard } from 'types/Attachments';
import useSetVideoThumbnailPostComposerForm from 'app/modules/posts/hooks/useSetVideoThumbnailPostComposerForm';
import useVideoThumbnailUploader from 'app/modules/posts/hooks/useVideoThumbnailUploader';
import TimeUtils from 'app/utils/time';
import NumberUtil from 'utils/number';
import { VideoPlayerRef } from '@kontentino/kontentino-post-previews';
import VideoPlayerUtils from 'app/utils/videoPlayer';
import PostsConfig from 'app/config/posts';
import FilesUtil from 'utils/files';
import { useToast } from 'app/hooks/useToast';
import { useFileDropzone } from 'app/hooks/useFileDropzone';
import BaseFileInfo from 'app/components/BaseFileInfo';
import VideoThumbnailSetupModalThumbnailPreview from './videoThumbnailSetupModal/VideoThumbnailSetupModalThumbnailPreview';
import VideoSlider from 'app/components/modals/videoThumbnailSetupModal/VideoSlider';
import { Trans } from 'react-i18next';
import clsx from 'clsx';

type Props = {
  video: VideoMediumAttachmentCard;
  isOpen: boolean;
  onClose: () => void;
};

const MAX_ALLOWED_VIDEO_DURATION_OFFSET = 0.1;

function getMaxAllowedVideoDuration(video: Props['video']) {
  return NumberUtil.clamp(
    video.meta.duration - MAX_ALLOWED_VIDEO_DURATION_OFFSET,
    0,
    video.meta.duration,
  );
}

const VideoThumbnailSetupModal: FC<Props> = ({ video, isOpen, onClose }) => {
  const [customImageThumbnailFiles, setCustomImageThumbnailFiles] = useState<
    File[]
  >([]);
  const [canShowThumbnail, setCanShowThumbnail] = useState(
    !!video.custom_thumb?.src ?? true,
  );
  const [selectedSecond, setSelectedSecond] = useState(0);
  const videoPlayerRef = useRef<VideoPlayerRef>(null);
  const setCustomThumbnail = useSetVideoThumbnailPostComposerForm();
  const toast = useToast();
  const hasCustomImageThumbnail = customImageThumbnailFiles.length > 0;
  const thumbnailUploader = useVideoThumbnailUploader({
    videoId: video.id,
    onMediaUploaded: setCustomThumbnail,
    thumbnailOffsetMilliseconds: hasCustomImageThumbnail
      ? undefined
      : TimeUtils.calcMilliseconds.seconds(selectedSecond),
  });
  const maxAllowedVideoDuration = getMaxAllowedVideoDuration(video);

  const dropzone = useFileDropzone({
    onDrop: (files) => {
      setCustomImageThumbnailFiles(files);
      setCanShowThumbnail(true);
    },
    maxSize: PostsConfig.INSTAGRAM_THUMBNAIL_CONFIG.MAX_SIZE,
    accept: PostsConfig.INSTAGRAM_THUMBNAIL_CONFIG.ACCEPT,
    maxFiles: PostsConfig.INSTAGRAM_THUMBNAIL_CONFIG.MAX_FILES,
  });

  const internalPlayer = videoPlayerRef.current?.player?.getInternalPlayer();

  function changeSelectedSecond(seconds: number) {
    setSelectedSecond(NumberUtil.clamp(seconds, 0, maxAllowedVideoDuration));
  }

  useEffect(() => {
    function seekListener(e: Event) {
      const { currentTime } = e.target as HTMLMediaElement;

      if (Number.isFinite(currentTime)) {
        changeSelectedSecond(currentTime);
      }
    }

    if (internalPlayer) {
      internalPlayer.addEventListener('seeked', seekListener);
    }

    return () => {
      if (internalPlayer) {
        internalPlayer.removeEventListener('seeked', seekListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPlayer]);

  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numberValue = parseFloat(value);
    const fractionValue = numberValue / maxAllowedVideoDuration;

    changeSelectedSecond(numberValue);
    setCanShowThumbnail(false);
    videoPlayerRef.current?.player?.seekTo(fractionValue, 'fraction');
  };

  const handleCreateThumbnailFromVideo = async () => {
    const thumbnailImage = VideoPlayerUtils.base64ImageFromVideoPlayer(
      PostsConfig.POST_COMPOSE_THUMBNAIL_SETUP_VIDEO_PLAYER_ID,
      {
        width: video.sizes.medium?.width ?? 0,
        height: video.sizes.medium?.height ?? 0,
      },
    );

    if (thumbnailImage) {
      const imageFile = await FilesUtil.base64ToPngFile(thumbnailImage);

      if (imageFile) {
        thumbnailUploader.upload([imageFile]);
      } else {
        toast(`Thumbnail was not set correctly. Please try again.`, 'warning');
      }
    }
  };

  const handleSubmitVideoThumbnail = async () => {
    if (hasCustomImageThumbnail) {
      thumbnailUploader.upload(customImageThumbnailFiles);
    } else {
      await handleCreateThumbnailFromVideo();
      setCanShowThumbnail(false);
    }
  };

  const handleDeleteCustomImageThumbnail = () => {
    setCustomImageThumbnailFiles([]);
    setSelectedSecond(0);
  };

  const isPortrait = () => {
    return (video.sizes.medium?.width ?? 0) < (video.sizes.medium?.height ?? 0);
  };

  return (
    <Modal
      size="xs"
      dataName={{
        wrapper: 'video-thumbnail-setup-modal',
        closeButton: 'video-thumbnail-setup-modal_close-button',
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header title="Custom thumbnail">
        <span className="tw-text-md tw-text-grayscale-140">
          <Trans
            i18nKey="thumbnailSetupDescription"
            components={[
              <span
                {...dropzone.getRootProps()}
                className="tw-cursor-pointer tw-text-primary-100 hover:tw-text-primary-100 hover:tw-underline"
                data-name="video-thumbnail-setup-modal_upload-cta"
                data-cy="video-thumbnail-setup-modal_upload-cta"
                onClick={dropzone.open}
              />,
              <input {...dropzone.getInputProps()} />,
            ]}
          />
        </span>
        {thumbnailUploader.isError && (
          <Alert variant="danger">{thumbnailUploader.error?.userMessage}</Alert>
        )}
      </Modal.Header>
      <Modal.Content className="tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
        <div
          className={clsx('tw-z-[1]', {
            'tw-max-w-[250px]': isPortrait(),
          })}
        >
          <VideoThumbnailSetupModalThumbnailPreview
            ref={videoPlayerRef}
            video={video}
            canShowThumbnail={canShowThumbnail}
            thumbnailSrc={
              hasCustomImageThumbnail
                ? URL.createObjectURL(customImageThumbnailFiles[0])
                : video.custom_thumb?.src ?? video.original_thumb?.src
            }
          />
        </div>
      </Modal.Content>
      <Modal.Footer className="tw-flex-1 tw-flex-col tw-items-stretch">
        {!hasCustomImageThumbnail && (
          <VideoSlider
            value={selectedSecond}
            onChange={handleSliderChange}
            duration={maxAllowedVideoDuration}
          />
        )}
        {customImageThumbnailFiles.map((file) => (
          <BaseFileInfo
            key={file.lastModified}
            file={file}
            onRemoveClick={handleDeleteCustomImageThumbnail}
          />
        ))}
        <div className="tw-flex tw-items-center tw-justify-end tw-gap-3">
          <Button
            disabled={thumbnailUploader.isLoading}
            onClick={onClose}
            variant="secondary"
            data-name="video-thumbnail-setup-modal_cancel-button"
            data-cy="video-thumbnail-setup-modal_cancel-button"
          >
            Cancel
          </Button>
          <Button
            isLoading={thumbnailUploader.isLoading}
            onClick={handleSubmitVideoThumbnail}
            data-name="video-thumbnail-setup-modal_submit-button"
            data-cy="video-thumbnail-setup-modal_submit-button"
          >
            Set thumbnail
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoThumbnailSetupModal;
