import { useQuery } from 'react-query';
import { MediaApi } from 'api/media';
import { queryKey } from 'constants/queryKey';
import useSetState from 'utils/hooks/useSetState';
import {
  MediaLibraryAlbum,
  MediaLibraryItem,
  MediaLibraryItems as MediaLibraryItemsType,
  MediaLibraryLimits,
} from 'types/MediaLibrary';
import { useToast } from 'app/hooks/useToast';
import { useEffect } from 'react';
import { MediaLibraryConfig } from 'app/config/mediaLibrary';

export type MediaLibraryContainerProps = {
  isShown: boolean;
  onHide: () => void;
  page: {
    id: number;
    type: number;
  };
  onSelectSubmit(media: MediaLibraryItemsType): void;
  limits?: Partial<MediaLibraryLimits>;
};

type State = {
  albumId: number | undefined;
  selectedMedia: MediaLibraryItemsType;
};

const DEFAULT_LIMITS: MediaLibraryLimits = {
  selectCount: MediaLibraryConfig.MEDIA_LIBRARY_ITEMS_SELECT_COUNT,
  allowedFiles: ['image', 'video'],
};

function useMediaLibraryContainer(props: MediaLibraryContainerProps) {
  const toast = useToast();
  const [state, setState] = useSetState<State>({
    albumId: undefined,
    selectedMedia: [],
  });
  const limits = {
    ...DEFAULT_LIMITS,
    ...props.limits,
  };

  useEffect(() => {
    if (!props.isShown) {
      resetSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isShown]);

  const albums = useQuery(queryKey.allMediaAlbums, MediaApi.getAlbums, {
    onSuccess: preselectAlbum,
  });

  function preselectAlbum(albums: MediaLibraryAlbum[]) {
    setState(({ albumId }) => {
      if (!albumId) {
        if (props.page.id) {
          const firstAlbumOfPreferredPage = albums.find((album) => {
            return album.page?.id === props.page.id;
          });

          if (firstAlbumOfPreferredPage) {
            return { albumId: firstAlbumOfPreferredPage.id };
          }
        }

        if (albums.length) {
          return { albumId: albums[0].id };
        }
      }

      return {};
    });
  }

  function resetSelection() {
    setState({ selectedMedia: [] });
  }

  function toggleSelectedMedium(mediumToToggle: MediaLibraryItem) {
    if (!limits.allowedFiles.includes(mediumToToggle.type)) {
      toast(`You can only select ${limits.allowedFiles.join(' and ')} files.`);
      return;
    }

    setState(({ selectedMedia }) => {
      const prevIds = selectedMedia.map((medium) => medium.id);

      if (prevIds.includes(mediumToToggle.id)) {
        return {
          selectedMedia: selectedMedia.filter(
            (medium) => medium.id !== mediumToToggle.id,
          ),
        };
      } else if (state.selectedMedia.length >= limits.selectCount) {
        return {
          selectedMedia: [...selectedMedia.splice(1), mediumToToggle],
        };
      } else {
        return { selectedMedia: [...selectedMedia, mediumToToggle] };
      }
    });
  }

  function submitSelected() {
    toast(`Selected ${state.selectedMedia.length} media`);
    props.onHide();
    props.onSelectSubmit(state.selectedMedia);
  }

  return {
    albums: albums.data ?? [],
    isLoadingAlbums: albums.isLoading,
    selectAlbumId: (albumId: number) => setState({ albumId }),
    toggleSelectedMedium,
    selectedMediaIds: state.selectedMedia.map((medium) => medium.id),
    state,
    submitSelected,
  };
}

export default useMediaLibraryContainer;
