import { mergeTailwindClasses } from '@kontentino/ui';
import React, { FC, useState } from 'react';
import { MediaPreviewImage } from 'app/components/compoundMediaPreview/MediaPreviewImage';
import MediaPreview from 'app/components/compoundMediaPreview';
import { MediaAsset } from '../../types';
import AssetPreviewDropdown from './asset/AssetPreviewDropdown';
import clsx from 'clsx';

export type Props = {
  asset: MediaAsset;
  className?: string;
};

const AssetCard: FC<Props> = ({ className, asset }) => {
  //  TODO UserPermissionGate
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div className={mergeTailwindClasses('', className)}>
      <MediaPreview.Root
        className="tw-h-[168px] tw-w-[210px]"
        isSelected={isSelected}
        onSelect={() => setIsSelected(!isSelected)}
      >
        <div className="tw-h-[168px]">
          <MediaPreviewImage
            className="tw-h-[168px] tw-w-full"
            src={asset?.url}
            // typeIndicator={<MediaPreviewTypeIndicator medium={medium} />}
          />
          <MediaPreview.Overlay />
          <MediaPreview.Actions
            className={clsx({
              'tw-opacity-100': isDropdownOpen,
            })}
          >
            <MediaPreview.ActionButton actionType="download" />

            <AssetPreviewDropdown
              isOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
              assetId={asset.id}
            />
          </MediaPreview.Actions>
        </div>
      </MediaPreview.Root>
      <div className="tw-mt-1">
        <div className="tw-flex tw-gap-1">
          {/* //logo */}
          <span className="tw-text-sm">Kontentino</span>
        </div>
        <p className="tw-text-sm tw-font-semibold">
          {asset?.original_filename}
        </p>
        <span className="tw-text-sm tw-font-regular">
          {asset?.usage_count}x used
        </span>
      </div>
    </div>
  );
};

export default AssetCard;
