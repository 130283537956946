import React, { FC } from 'react';
import { MediaLibraryItem } from 'types/MediaLibrary';
import style from './/mediaLibraryItemsContainer/LibraryMedia.module.scss';
import Skeleton from './/mediaLibraryItemsContainer/Skeleton';
import MediaListAlertMessage from './/mediaLibraryItemsContainer/MediaListAlertMessage';
import { useLibraryMediaListContainer } from './/mediaLibraryItemsContainer/LibraryMedia.hooks';
import LoadingMoreMedia from './/mediaLibraryItemsContainer/LoadingMoreMedia';
import RunningUploadsItems from './/mediaLibraryItemsContainer/RunningUploadsItems';
import MediaLibraryItems from './/mediaLibraryItemsContainer/MediaLibraryItems';
import ImageEditor from 'app/components/ImageEditor';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { Icon, Tooltip } from '@kontentino/ui';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import clsx from 'clsx';
type Props = {
  onToggleSelectedMedium(medium: MediaLibraryItem): void;
  albumId?: number;
  page: {
    id: number;
    type: number;
  };
  selectedMediaIds: number[];
};

const MediaLibraryItemsContainer: FC<Props> = (props) => {
  const {
    paginatedMedia,
    deleteMedium,
    mediaUploader,
    hideImageEditor,
    editImage,
    imageToEdit,
  } = useLibraryMediaListContainer({
    albumId: props.albumId,
    page: props.page,
  });

  return (
    <div
      className={style.libraryItems}
      ref={paginatedMedia.rootRef}
      {...mediaUploader.dropzone.getRootProps()}
    >
      <div className={style.libraryAttachmentsList}>
        <UserPermissionGate scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}>
          <Tooltip content="Upload from computer to album">
            <div
              className={clsx(
                style.libraryItem,
                'tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded tw-border tw-border-dashed tw-border-primary-100 tw-bg-primary-10 tw-text-primary-100 hover:tw-bg-primary-20',
              )}
              onClick={mediaUploader.dropzone.open}
            >
              <Icon icon={faPlus} size="lg" />
            </div>
          </Tooltip>
        </UserPermissionGate>
        <RunningUploadsItems
          runningUploads={mediaUploader.runningUploads}
          selectedMediaIds={props.selectedMediaIds}
          onToggleSelectedMedium={props.onToggleSelectedMedium}
          itemClassName={style.libraryItem}
          onEditImage={editImage}
          onDeleteMedium={deleteMedium}
        />
        {paginatedMedia.isError && <MediaListAlertMessage type="error" />}
        {paginatedMedia.isLoading && <Skeleton />}
        {paginatedMedia.media.length !== 0 && (
          <MediaLibraryItems
            media={paginatedMedia.media}
            selectedMediaIds={props.selectedMediaIds}
            onToggleSelectedMedium={props.onToggleSelectedMedium}
            onEditImage={editImage}
            onDeleteMedium={deleteMedium}
          />
        )}
        {paginatedMedia.hasNextPage && (
          <LoadingMoreMedia
            ref={paginatedMedia.sentryRef}
            className={style.libraryItem}
          />
        )}
      </div>
      <input {...mediaUploader.dropzone.getInputProps()} />
      {mediaUploader.dropzone.isDragActive && (
        <div className={style.dragOver}>Drop it</div>
      )}
      {imageToEdit && (
        <ImageEditor
          pageId={props.page.id}
          imageSrc={imageToEdit.src}
          onClose={hideImageEditor}
          onImageEdited={(file) => mediaUploader.upload({ files: [file] })}
        />
      )}
    </div>
  );
};

export default MediaLibraryItemsContainer;
