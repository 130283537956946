import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import ProgressIndicator from 'app/components/ProgressIndicator';
import { useTranslation } from 'react-i18next';
import { useMediaPreview } from './MediaPreviewRoot';
import { Checkbox, Icon, mergeTailwindClasses } from '@kontentino/ui';
import { faFiles } from '@fortawesome/pro-regular-svg-icons/faFiles';
import Logger from 'utils/logger';
export type MediaPreviewImageProps = {
  src: string | undefined;
  fileName?: string;
  loadingProgress?: number;
  typeIndicator?: ReactNode;
  className?: string;
};

export const MediaPreviewImage: FC<MediaPreviewImageProps> = ({
  src,
  loadingProgress,
  typeIndicator,
  fileName,
  className,
}) => {
  const { orderIndex, isSelected, isLoading, isError, isPending, onSelect } =
    useMediaPreview();
  const { t } = useTranslation();
  Logger.log(onSelect, 'onSelect');
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-absolute tw-inset-0 tw-bg-grayscale-10 tw-bg-cover tw-bg-center',
          className,
        ),
      )}
      style={src ? { backgroundImage: `url(${src})` } : undefined}
    >
      {typeof orderIndex === 'number' && (
        <div
          className={clsx(
            'tw-absolute tw-left-1 tw-top-1 tw-flex tw-min-h-[1rem] tw-min-w-[1rem] tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5 tw-px-1 tw-text-xs tw-font-semibold tw-text-grayscale-180',
            {
              'tw-bg-primary-100 tw-text-white': isSelected,
            },
          )}
        >
          {orderIndex}
        </div>
      )}
      {typeIndicator && (
        <div className="tw-absolute tw-right-1 tw-top-1">{typeIndicator}</div>
      )}
      {!src && !isLoading && (
        <div className="tw-relative tw-h-full tw-flex-1 tw-flex-col tw-justify-between tw-p-1">
          <Icon icon={faFiles} className="tw-text-grayscale-100" />
          <p className="tw-absolute tw-bottom-1 tw-line-clamp-2 tw-break-all tw-text-xs tw-text-grayscale-180">
            {fileName ?? ''}
          </p>
        </div>
      )}
      {isSelected && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-rounded-md tw-border-[2px] tw-border-primary-100" />
      )}
      {onSelect && (
        <div
          className={mergeTailwindClasses(
            clsx(
              'tw-absolute tw-left-2 tw-top-2  tw-z-10 tw-hidden group-hover:tw-block',
              { 'tw-block': isSelected },
            ),
          )}
        >
          <Checkbox
            onChange={() => null}
            checked={isSelected}
            size="small"
            onClick={onSelect}
          />
        </div>
      )}
      {isLoading && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-cursor-wait tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-180/25 tw-text-md tw-text-white">
          {typeof loadingProgress === 'number' && (
            <div>
              <ProgressIndicator progress={loadingProgress} color="white" />
            </div>
          )}
          <span className="tw-text-xs tw-font-medium tw-text-white">
            {t('uploading')}&hellip;
          </span>
        </div>
      )}
      {isError && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-100/25 tw-text-md tw-text-white">
          <span className="tw-text-xs tw-text-white">{t('error')}</span>
        </div>
      )}
      {isPending && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-100/25 tw-text-md tw-text-white">
          <span className="tw-text-sm tw-text-white">
            {t('pending')}&hellip;
          </span>
        </div>
      )}
    </div>
  );
};
