import { Icon } from '@kontentino/ui';
import { faRectangleVertical } from '@fortawesome/pro-light-svg-icons/faRectangleVertical';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
export const SOCIAL_MEDIA_PRESETS_GROUPS = [
  {
    titleKey: 'instagram',
    items: [
      {
        titleKey: 'Instagram story',
        ratio: '0.5625',
      },
    ],
  },
  {
    titleKey: 'pinterest',
    items: [
      {
        titleKey: 'Portrait',
        ratio: '0.6666',
      },
    ],
  },
];

export const CROP_PRESETS = [
  {
    titleKey: 'Vertical',
    ratio: '0.8',
    descriptionKey: '4:5',
    icon: () => <Icon icon={faRectangleVertical} withoutBoundary />,
  },
  {
    titleKey: 'Square',
    ratio: '1',
    descriptionKey: '1:1',
    icon: () => <Icon icon={faSquare} withoutBoundary />,
  },
];
