import React, { FC, useEffect, useState } from 'react';
import MediaLibraryModal from 'app/modules/mediaLibrary/MediaLibraryModal';
import SelectedAttachments from 'app/components/mediaAttachments/components/SelectedAttachments';
import ImageEditor from 'app/components/ImageEditor';
import { AttachmentConvertor } from 'utils/attachments';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { Attachments, VideoMediumAttachmentCard } from 'types/Attachments';
import { useMediaAttachmentsContainer } from 'app/components/mediaAttachments/hooks/useMediaAttachmentsContainer';
import clsx from 'clsx';
import { MediaLibraryItemFileType } from 'types/MediaLibrary';
import DropZone from './fileSourceInput/dropZone';
import { RunningUpload } from 'types/RunningUpload';
import VideoThumbnailSetupModal from 'app/components/modals/VideoThumbnailSetupModal';

export type MediaAttachmentsProps = Props;

type Props = {
  page: {
    id: number;
    type: number;
  };
  attachments: Attachments;
  onAttachmentsChange: (
    fn: (attachments: Attachments) => Partial<Attachments>,
  ) => void;
  disabled?: boolean;
  maxCount: number;
  allowedFiles: MediaLibraryItemFileType[];
  onRunningUploadsChange?: (runningUploads: RunningUpload[]) => void;
  canSetupVideoThumbnail?: boolean;
};

const MediaAttachments: FC<Props> = ({
  attachments,
  disabled,
  onAttachmentsChange,
  page,
  maxCount,
  allowedFiles,
  onRunningUploadsChange,
  canSetupVideoThumbnail,
}) => {
  const [videoAttachmentForThumbnail, setVideoAttachmentForThumbnail] =
    useState<VideoMediumAttachmentCard | null>(null);
  const container = useMediaAttachmentsContainer({
    attachments,
    maxCount,
    allowedFiles,
    disabled,
    onAttachmentsChange,
    page,
  });

  useEffect(() => {
    onRunningUploadsChange?.(container.mediaUploader.runningUploads);
  }, [container.mediaUploader.runningUploads, onRunningUploadsChange]);

  const inputProps = container.mediaUploader.dropzone.getInputProps();

  return (
    <div
      data-name="media-attachments"
      className={clsx('tw-flex tw-gap-2', {
        'tw-pointer-events-none tw-cursor-not-allowed tw-opacity-50': disabled,
      })}
    >
      <input {...inputProps} data-cy="media-attachments-input" />
      <UserPermissionGate scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}>
        <>
          {container.totalAttachmentsLength === 0 && (
            <DropZone
              {...container.mediaUploader.dropzone.getRootProps()}
              isDragActive={container.mediaUploader.dropzone.isDragActive}
              specifications={{
                accept: inputProps.accept,
                multiple: inputProps.multiple,
              }}
              fileSources={container.fileSources}
            >
              <DropZone.DropZoneHelperText />
              <DropZone.DropZoneFileSelect />
              <DropZone.DropZoneActiveDragOverlay />
            </DropZone>
          )}
        </>
      </UserPermissionGate>

      {container.totalAttachmentsLength > 0 && (
        <>
          <SelectedAttachments
            attachmentsCards={container.attachments.cards}
            onRemoveAttachmentAtIndex={container.removeAttachmentAtIndex}
            onCustomThumbnailClick={
              canSetupVideoThumbnail
                ? setVideoAttachmentForThumbnail
                : undefined
            }
            onAttachmentIndexMove={container.moveAttachmentIndex}
            updateAttachmentMedium={container.updateAttachmentMedium}
            onEditImage={container.editImage}
            runningUploads={container.mediaUploader.runningUploads}
          />
          {maxCount > container.totalAttachmentsLength && (
            <UserPermissionGate
              scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}
            >
              <DropZone
                {...container.mediaUploader.dropzone.getRootProps()}
                isDragActive={container.mediaUploader.dropzone.isDragActive}
                specifications={{
                  accept: inputProps.accept,
                  multiple: inputProps.multiple,
                }}
                fileSources={container.fileSources}
                removeDefaultClassName
                className="tw-relative"
              >
                <DropZone.DropZoneFilePicker />
                <DropZone.DropZoneActiveDragOverlay />
              </DropZone>
            </UserPermissionGate>
          )}
        </>
      )}
      <UserPermissionGate scopes={UserPermission.MEDIA_LIBRARY_MANAGE_MEDIA}>
        <MediaLibraryModal
          page={page}
          isShown={container.mediaLibrary.isShown}
          onHide={container.mediaLibrary.hide}
          limits={{
            selectCount: maxCount - attachments.cards.length,
            allowedFiles,
          }}
          onSelectSubmit={(items) =>
            container.addAttachments(
              AttachmentConvertor.fromMediaLibraryItems(items),
            )
          }
        />
      </UserPermissionGate>
      {container.imageToEdit && (
        <ImageEditor
          pageId={page.id}
          imageSrc={container.imageToEdit.src}
          onClose={container.hideImageEditor}
          onImageEdited={(file) => {
            if (typeof container.imageToEdit?.index === 'number') {
              container.removeAttachmentAtIndex(container.imageToEdit.index);
            }
            container.mediaUploader.upload({ files: [file] });
          }}
        />
      )}
      {videoAttachmentForThumbnail && (
        <VideoThumbnailSetupModal
          video={videoAttachmentForThumbnail}
          isOpen={!!videoAttachmentForThumbnail}
          onClose={() => setVideoAttachmentForThumbnail(null)}
        />
      )}
    </div>
  );
};

export default MediaAttachments;
