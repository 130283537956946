import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Icon, IconButton } from '@kontentino/ui';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
type Props = {
  children: ReactNode;
  step?: number;
  itemsCount: number;
};

const AttachmentsCarousel: FC<Props> = ({
  step = 100,
  itemsCount,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollEdgePosition, setScrollEdgePosition] = useState<
    'left' | 'right' | 'middle'
  >('left');

  useEffect(() => {
    setIsScrollable(() => {
      if (contentRef.current) {
        return contentRef.current.scrollWidth > contentRef.current.clientWidth;
      }
      return false;
    });
  }, [itemsCount, setIsScrollable]);

  useEffect(() => {
    function getScrollEdgePosition() {
      if (contentRef.current) {
        const scrollWidth = contentRef.current.scrollWidth;
        const clientWidth = contentRef.current.clientWidth;
        const scrollLeft = contentRef.current.scrollLeft;

        if (scrollLeft === 0) {
          return 'left';
        } else if (scrollLeft + clientWidth === scrollWidth) {
          return 'right';
        } else {
          return 'middle';
        }
      }

      return 'left';
    }

    function scrollListener() {
      setScrollEdgePosition(getScrollEdgePosition());
    }

    const element = contentRef.current;

    element?.addEventListener('scroll', scrollListener);

    return () => {
      element?.removeEventListener('scroll', scrollListener);
    };
  }, [setScrollEdgePosition]);

  function scrollLeft() {
    if (contentRef.current) {
      contentRef.current.scrollBy({
        left: step * -1,
        behavior: 'smooth',
      });
    }
  }

  function scrollRight() {
    if (contentRef.current) {
      contentRef.current.scrollBy({
        left: step,
        behavior: 'smooth',
      });
    }
  }

  return (
    <div className="tw-relative tw-flex tw-items-center tw-overflow-hidden">
      <div
        className="tw-flex tw-flex-1 tw-overflow-x-auto tw-overflow-y-hidden tw-scrollbar-hide"
        ref={contentRef}
      >
        {children}
      </div>

      {isScrollable && (
        <>
          {scrollEdgePosition !== 'left' && (
            <IconButton
              variant="secondary"
              icon={<Icon icon={faChevronLeft} />}
              onClick={scrollLeft}
              className="tw-absolute tw-left-0 tw-h-8 tw-w-8 tw-rounded-full tw-bg-white"
            />
          )}
          {scrollEdgePosition !== 'right' && (
            <IconButton
              variant="secondary"
              icon={<Icon icon={faChevronRight} />}
              onClick={scrollRight}
              className="tw-absolute tw-right-0 tw-h-8 tw-w-8 tw-rounded-full tw-bg-white"
            />
          )}
        </>
      )}
    </div>
  );
};

export default AttachmentsCarousel;
