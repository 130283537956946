import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import PostsConfig from 'app/config/posts';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import DatePickerDropdown from 'app/components/datepicker-dropdown';
import DatePickerHeaderCustomPeriod from 'app/components/datepicker-dropdown/custom-header/DatePickerHeaderCustomPeriod';
import DatePickerFooter from 'app/components/datepicker-dropdown/DatePickerFooter';
import {
  getDatesAutoSelectPeriodWithConstrains,
  getDatesWithConstraints,
  isDateWithinRange,
} from 'app/components/datepicker-dropdown/utils';
import useSetState from 'utils/hooks/useSetState';
import { getRangePlaceholderLabel } from 'app/components/datepicker-dropdown/utils/placeholder';

const MIN_DATE = dayjs()
  .subtract(PostsConfig.MIN_PREVIOUS_NUMBER_OF_YEARS, 'year')
  .startOf('year');

const MAX_DATE = dayjs();

type Props = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  autoSelectPeriod?: number;
  onChange: (
    {
      startDate,
      endDate,
    }: {
      startDate: Dayjs | null;
      endDate: Dayjs | null;
    },
    setPeriod?: boolean,
  ) => void;
  clearComparedDateFilter: () => void;
};

const InsightsPeriodToCompareDatePicker: FC<Props> = ({
  startDate,
  endDate,
  autoSelectPeriod,
  onChange,
  clearComparedDateFilter,
}) => {
  const [error, setError] = useSetState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: true,
    endDate: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [startDateTemp, setStartDateTemp] = useState<Dayjs | null>(
    startDate ?? null,
  );
  const [endDateTemp, setEndDateTemp] = useState<Dayjs | null>(endDate ?? null);

  const onCancelCustomPeriod = () => {
    setStartDateTemp(startDate);
    setEndDateTemp(endDate);
  };

  const onDateRangeSubmit = () => {
    onChange(
      {
        startDate: startDateTemp ?? null,
        endDate: endDateTemp ?? null,
      },
      true,
    );
    setIsOpen(false);
  };

  const onRangeSelect = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (typeof autoSelectPeriod === 'number') {
      let autoSelectedPeriod = getDatesWithConstraints(
        { startDate: dayjs(start), endDate: dayjs(end) },
        { maxEndDate: MAX_DATE },
      );

      autoSelectedPeriod = getDatesAutoSelectPeriodWithConstrains(
        autoSelectedPeriod,
        autoSelectPeriod,
        {
          maxEndDate: MAX_DATE,
        },
      );

      setStartDateTemp(autoSelectedPeriod?.startDate ?? dayjs(start));
      setEndDateTemp(autoSelectedPeriod?.endDate ?? dayjs(end));
      return;
    }

    setStartDateTemp(start ? dayjs(start) : null);
    setEndDateTemp(end ? dayjs(end) : null);
  };

  const label = useMemo(() => {
    return getRangePlaceholderLabel({
      startDateTemp,
      endDateTemp,
    });
  }, [startDateTemp, endDateTemp]);

  useEffectOnce(() => {
    return () => clearComparedDateFilter();
  });

  useEffect(() => {
    if (MIN_DATE && MAX_DATE && startDate && endDate) {
      setError({
        startDate: startDate
          ? isDateWithinRange(startDate, MIN_DATE, MAX_DATE)
          : false,
        endDate: endDate
          ? isDateWithinRange(endDate, MIN_DATE, MAX_DATE)
          : false,
      });
    }
  }, [startDate, endDate, setError]);

  return (
    <DatePickerDropdown
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      label={label}
      startDate={startDateTemp?.toDate() ?? null}
      endDate={endDateTemp?.toDate() ?? null}
      selectsRange={true}
      onChange={(val) => {
        onRangeSelect(val as [Date | null, Date | null]);
      }}
      maxDate={MAX_DATE.toDate()}
      renderCustomHeader={(headerProps) => (
        <DatePickerHeaderCustomPeriod
          {...headerProps}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          error={error}
          startDate={startDateTemp ?? startDate}
          endDate={endDateTemp ?? endDate}
          onStartDateChange={(date) => setStartDateTemp(date)}
          onEndDateChange={(date) => setEndDateTemp(date)}
        />
      )}
    >
      <DatePickerFooter
        isCancelDisabled={
          startDate === startDateTemp && endDate === endDateTemp
        }
        onDateRangeSubmit={onDateRangeSubmit}
        onRangeSelectCancel={onCancelCustomPeriod}
      />
    </DatePickerDropdown>
  );
};

export default InsightsPeriodToCompareDatePicker;
