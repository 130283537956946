import Modal from 'components/shared/Modal';
import React, { FC } from 'react';
import AlbumSelect from './components/mediaLibraryModal/AlbumSelect';
import useMediaLibraryContainer, {
  MediaLibraryContainerProps,
} from './hooks/useMediaLibraryContainer';
import LibraryMedia from 'app/modules/mediaLibrary/components/mediaLibraryModal/MediaLibraryItemsContainer';
import Footer from './components/mediaLibraryModal/Footer';

type Props = MediaLibraryContainerProps;

const MediaLibrary: FC<Props> = (props) => {
  const container = useMediaLibraryContainer(props);

  return (
    <Modal
      open={props.isShown}
      onClose={props.onHide}
      size="lg"
      isContentHeightAutoResizable={false}
    >
      <Modal.Header title="Media Library">
        <AlbumSelect
          albums={container.albums}
          onSelect={(album) => container.selectAlbumId(album.id)}
          selectedAlbumId={container.state.albumId}
        />
      </Modal.Header>
      <Modal.Content className="tw-h-[min(800px,90vh-292px)] tw-overflow-auto">
        <LibraryMedia
          selectedMediaIds={container.selectedMediaIds}
          onToggleSelectedMedium={container.toggleSelectedMedium}
          albumId={container.state.albumId}
          page={props.page}
        />
      </Modal.Content>
      <Footer
        onCancel={props.onHide}
        onSelectSubmit={container.submitSelected}
        selectedCount={container.selectedMediaIds.length}
      />
    </Modal>
  );
};

export default MediaLibrary;
