import React, { FC } from 'react';
import style from 'components/shared/selectList/SelectList.module.scss';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode | React.ReactNode[] | null;
  className?: string;
};

const SelectList: FC<Props> = ({ children, className, ...props }) => (
  <div className={clsx(style.list, className)} {...props}>
    {children}
  </div>
);

export default SelectList;
