import Logger from 'utils/logger';
import DomUtils from 'app/utils/dom';

const VideoPlayerUtils = {
  base64ImageFromVideoPlayer: (
    videoPlayerId: string,
    config?: {
      width: number;
      height: number;
    },
  ) => {
    const video = DomUtils.getElementById<HTMLVideoElement>(videoPlayerId);
    const canvas = document.createElement('canvas');

    try {
      if (!!video && !!canvas) {
        canvas.style.display = 'none';
        document.body.appendChild(canvas);

        const width = config?.width || video.clientWidth;
        const height = config?.height || video.clientHeight;

        canvas.width = width;
        canvas.height = height;

        const canvasContext = canvas.getContext('2d');

        if (canvasContext) {
          canvasContext.drawImage(video, 0, 0, width, height);
          return canvas.toDataURL('image/png');
        }
      }
    } catch (e) {
      Logger.error(e, 'Can not create image from video player');
    } finally {
      canvas.remove();
    }

    return null;
  },
};

export default VideoPlayerUtils;
