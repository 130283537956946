import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Field } from '@kontentino/ui';
import { ApiClientError } from 'api/client';
import { useToast } from 'app/hooks/useToast';
import Modal from 'components/shared/Modal';
import React, { useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import { MediaLibraryApi } from '../../api/mediaLibrary';
import {
  albumFormValidationSchema,
  AlbumFormValues,
} from './form/albumFormSchema';
import { usePage } from 'modules/page/pageSelector';
import AlbumPageTypeSelect from './form/AlbumPageTypeSelect';

interface AlbumEditModalProps {
  onClose: () => void;
  isOpen: boolean;
  initialData: AlbumFormValues;
  albumId: string;
}

const AlbumAssignModal: React.FC<AlbumEditModalProps> = ({
  onClose,
  isOpen,
  initialData,
  albumId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { pages } = usePage();
  const [selectedOption, setSelectedOption] = useState<{
    id: string;
    type: string;
    model: string;
  } | null>(null);

  const mutation = useMutation(MediaLibraryApi.updateAlbum, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries('allAlbums');
      toast('Changes to the album have been saved.', 'success');
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
  });

  function handleSubmitData(data: AlbumFormValues) {
    const updatedData = { ...data };
    if (selectedOption) {
      updatedData.assigned_model_id = selectedOption.id;
      updatedData.assigned_model_type = selectedOption.type;
    }

    mutation.mutate({ id: albumId, data: updatedData });
  }

  const formMethods = useForm<AlbumFormValues>({
    resolver: zodResolver(albumFormValidationSchema),
    defaultValues: initialData,
  });

  const initialAssignedModelId = initialData?.assigned_model_id;
  const initialAssignedModelType = initialData?.assigned_model_type;

  const initialOption = pages.find(
    (page) =>
      page.id.toString() === initialAssignedModelId &&
      page.type.toString() === initialAssignedModelType,
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header title="Assign album" />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmitData)}>
          <Modal.Content className="tw-py-4">
            <div className="tw-flex tw-flex-col tw-pb-2">
              <Field.Group>
                <Controller
                  name="assigned_model_id"
                  control={formMethods.control}
                  defaultValue={initialOption?.id.toString() || undefined}
                  render={({ field: { onChange, value } }) => (
                    <AlbumPageTypeSelect
                      pages={pages}
                      onSelect={(data) => {
                        if (data) {
                          setSelectedOption({
                            id: data.id,
                            type: data.type,
                            model: data.model,
                          });
                          onChange(data.id);
                        } else {
                          setSelectedOption(null);
                          onChange(null);
                        }
                      }}
                      value={value}
                    />
                  )}
                />
              </Field.Group>
            </div>
          </Modal.Content>
          <Modal.Footer withDivider>
            <Button
              type="submit"
              className="tw-w-full"
              variant="primary"
              data-cy="album-assign_save-button"
              data-name="album-assign_save-button"
            >
              Confirm
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AlbumAssignModal;
