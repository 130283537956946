import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const albumFormValidationSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  assigned_model_id: z.string().optional(),
  assigned_model_name: z.string().optional(),
  assigned_model_type: z.string().optional(),
  parent_id: z.string().optional(),
});

export type AlbumFormValues = z.infer<typeof albumFormValidationSchema>;

export const albumFormResolver = zodResolver(albumFormValidationSchema);

export const albumFormInitialValues: AlbumFormValues = {
  name: '',
  description: '',
  assigned_model_id: '',
  assigned_model_name: '',
  assigned_model_type: '',
};
