import React from 'react';

export const lockAxisInTransformStyle = (
  axis: 'X' | 'Y',
  style: React.CSSProperties | undefined,
) => {
  if (style?.transform) {
    const axisLock =
      axis === 'X'
        ? `${style.transform.split(',').shift()}, 0px)`
        : `translate(0px,${style.transform.split(',').pop()}`;

    return {
      ...style,
      transform: axisLock,
    };
  }

  return style;
};
