import { useState } from 'react';

export type ImageEditorInput = {
  src: string;
  id?: number;
  index?: number;
};

export const useImageEditor = () => {
  const [imageToEdit, setImageToEdit] = useState<ImageEditorInput>();

  function editImage(image: ImageEditorInput) {
    setImageToEdit(image);
  }

  function hideImageEditor() {
    setImageToEdit(undefined);
  }

  return { imageToEdit, editImage, hideImageEditor };
};
