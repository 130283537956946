import SelectList from 'components/shared/SelectList';
import SelectPanel from 'components/shared/SelectPanel';
import React, { FC, useMemo, useState } from 'react';
import style from 'app/modules/mediaLibrary/components/mediaLibraryModal/albumSelect/AlbumSelect.module.scss';
import useDebounce from 'utils/hooks/useDebounce';
import Placeholder from 'app/modules/mediaLibrary/components/mediaLibraryModal/albumSelect/Placeholder';
import AlbumItem from 'app/modules/mediaLibrary/components/mediaLibraryModal/albumSelect/AlbumItem';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';
import { MediaLibraryAlbum } from 'types/MediaLibrary';
import StringUtils from 'utils/string';

type Props = {
  albums: MediaLibraryAlbum[];
  onSelect: (album: MediaLibraryAlbum) => void;
  selectedAlbumId?: number;
};

const AlbumSelect: FC<Props> = (props) => {
  const { elementRef, removeListener, addListener } =
    useOutsideClick<HTMLDivElement>(toggleList);
  const [isListShown, setListShown] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 250);

  function toggleList() {
    setListShown((prevShown) => {
      if (prevShown) {
        removeListener();
      } else {
        addListener();
      }

      return !prevShown;
    });
  }

  function getFilteredAlbums() {
    return props.albums.filter((album) =>
      StringUtils.isSubstring(debouncedSearch, album.name + album.page?.name),
    );
  }

  function getSelectedAlbum() {
    return props.albums.find((album) => album.id === props.selectedAlbumId);
  }

  function selectAlbum(album: MediaLibraryAlbum) {
    props.onSelect(album);
    toggleList();
  }

  const filteredAlbums = useMemo(getFilteredAlbums, [
    debouncedSearch,
    props.albums,
  ]);
  const selectedAlbum = useMemo(getSelectedAlbum, [
    props.selectedAlbumId,
    props.albums,
  ]);

  return (
    <div className={style.albumSelect} ref={elementRef}>
      <Placeholder album={selectedAlbum} onClick={toggleList} />
      {isListShown && (
        <SelectPanel
          searchValue={search}
          onSearchChange={setSearch}
          className={style.albumsList}
        >
          <SelectList>
            {filteredAlbums.map((album) => (
              <AlbumItem
                key={album.id}
                album={album}
                onClick={() => selectAlbum(album)}
              />
            ))}
          </SelectList>
        </SelectPanel>
      )}
    </div>
  );
};

export default AlbumSelect;
