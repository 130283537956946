import { queryKey } from 'constants/queryKey';
import React, { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import AlbumCard from './new/AlbumCard';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import { Button, Icon, TextLink } from '@kontentino/ui';
import Assets from './Assets';
import Skeleton from 'react-loading-skeleton';
import ArrayUtils from 'app/utils/array';
import AlbumCreateModal from './new/AlbumCreateModal';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { PageThunk } from 'modules/page/pageActions';
import { useDispatch } from 'react-redux';
import { MediaLibraryApi } from '../api/mediaLibrary';
import { MediaAlbum } from '../types';
import AssetCreateModal from './new/AssetCreateModal';
import SearchTextInput from 'app/components/SearchTextInput';
import useDebounce from 'utils/hooks/useDebounce';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import NoAlbumsFound from './new/album/NoAlbumsFound';
import NoAssetsFound from './new/asset/NoAssetsFound';

interface Props {}

const MediaLibrary: FC<Props> = (props) => {
  const skeletons = ArrayUtils.generate(4);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openAssetCreateModal, setOpenAssetCreateModal] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const debouncedSearch = useDebounce(value, 800);
  useEffectOnce(() => {
    dispatch(PageThunk.requestPages());
  });

  const { data: filteredAlbums, isLoading: areFilteredAlbumsLoading } =
    useQuery(
      [queryKey.allAlbums(), debouncedSearch],
      async () => {
        const response = await MediaLibraryApi.getAllAlbums({
          name: debouncedSearch,
        });
        return response.data;
      },
      {
        enabled: !!debouncedSearch,
      },
    );

  const { data: albums, isLoading: areAlbumsLoading } = useQuery(
    queryKey.allAlbums(),
    async () => {
      const response = await MediaLibraryApi.getAllAlbums();
      return response.data;
    },
  );

  const allAlbums = useMemo(() => {
    return debouncedSearch.length ? filteredAlbums : albums;
  }, [filteredAlbums, albums, debouncedSearch]);

  const { data: assets, isLoading: areAssetsLoading } = useQuery(
    queryKey.allAssets(),
    async () => {
      const response = await MediaLibraryApi.getAssets();
      return response.data;
    },
  );

  const { data: filteredAssets, isLoading: areFilteredAssetsLoading } =
    useQuery(
      [queryKey.allAssets(), debouncedSearch],
      async () => {
        const response = await MediaLibraryApi.getAssets({
          name: debouncedSearch,
        });
        return response.data;
      },
      {
        enabled: !!debouncedSearch,
      },
    );

  const allAssets = useMemo(() => {
    return debouncedSearch.length ? filteredAssets : assets;
  }, [filteredAssets, assets, debouncedSearch]);

  const handleSearchFieldValueChange = (value: string) => {
    setValue(value);
  };

  return (
    <>
      <div className="tw-flex tw-h-full tw-flex-col  tw-overflow-y-auto tw-p-6">
        <div className="w-full tw-mb-6 tw-flex tw-h-9 tw-items-center tw-justify-between">
          <h1 className="tw-text-lg tw-font-semibold">Media library</h1>
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
            <SearchTextInput
              onClear={() => handleSearchFieldValueChange('')}
              data-name="albums_search-field"
              data-cy="albums_search-field"
              value={value}
              onChange={(e) => {
                handleSearchFieldValueChange(e.target.value);
              }}
              className="!tw-h-10"
              disabled={areAlbumsLoading || areAssetsLoading}
            />
            <Button
              variant="primary"
              iconBefore={<Icon icon={faPlus} />}
              onClick={() => setOpenCreateModal(true)}
              size="medium"
            >
              Album
            </Button>

            <Button
              variant="primary"
              iconBefore={
                <Icon
                  icon={faPlus}
                  onClick={() => setOpenAssetCreateModal(true)}
                />
              }
              size="medium"
            >
              Asset
            </Button>
          </div>
        </div>

        <section className="tw-flex tw-flex-col">
          <h2 className="tw-mb-6 tw-text-base tw-font-semibold">Albums</h2>
          {!areAlbumsLoading && allAlbums?.length === 0 && <NoAlbumsFound />}

          {areAlbumsLoading ||
            (areFilteredAlbumsLoading && (
              <div className="tw-flex tw-w-full tw-flex-wrap tw-gap-4">
                {skeletons.map((i) => (
                  <Skeleton height={52} width={324} key={i} />
                ))}
              </div>
            ))}

          {allAlbums && allAlbums.length > 0 && (
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              {allAlbums.slice(0, 8).map((album: MediaAlbum) => (
                <AlbumCard key={album.id} album={album} />
              ))}
            </div>
          )}
          {allAlbums && allAlbums.length > 8 && (
            <div className="tw-mt-4 tw-flex tw-self-end">
              <TextLink>
                <Link to={routes.ALBUMS}>All albums</Link>
              </TextLink>
            </div>
          )}
        </section>

        <section className="tw-mt-6">
          {allAssets && <Assets assets={allAssets} />}

          {!areAssetsLoading && allAssets?.length === 0 && <NoAssetsFound />}

          {(areAssetsLoading || areFilteredAssetsLoading) &&
            allAssets?.length === 0 && (
              <div className="tw-flex tw-w-full tw-gap-4">
                {skeletons.map((i) => (
                  <Skeleton height={210} width={168} key={i} />
                ))}
              </div>
            )}
        </section>
      </div>
      {openAssetCreateModal && (
        <AssetCreateModal
          isOpen={openAssetCreateModal}
          onClose={() => setOpenAssetCreateModal(false)}
        />
      )}
      {openCreateModal && (
        <AlbumCreateModal
          isOpen={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
        />
      )}
    </>
  );
};

export default MediaLibrary;
