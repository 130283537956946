import { Button } from '@kontentino/ui';
import { ApiClientError } from 'api/client';
import { useToast } from 'app/hooks/useToast';
import Modal from 'components/shared/Modal';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import AlbumForm from './form/AlbumForm';
import { MediaLibraryApi } from '../../api/mediaLibrary';
import { queryKey } from 'constants/queryKey';
import {
  albumFormInitialValues,
  albumFormResolver,
  AlbumFormValues,
} from './form/albumFormSchema';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  parentAlbumId: string;
};

const SubAlbumCreateModal: React.FC<Props> = ({
  onClose,
  isOpen,
  parentAlbumId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(MediaLibraryApi.createAlbum, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(queryKey.albumDetail(parentAlbumId));
      queryClient.invalidateQueries('allAlbums');
      toast('Album has been added.', 'success');
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
  });

  function handleSubmitData(data: AlbumFormValues) {
    mutation.mutate(data);
  }

  const formMethods = useForm<AlbumFormValues>({
    resolver: albumFormResolver,
    defaultValues: {
      ...albumFormInitialValues,
      parent_id: parentAlbumId,
    },
  });

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header title="Add album" />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmitData)}>
          <Modal.Content>
            <AlbumForm isSubAlbum />
          </Modal.Content>
          <Modal.Footer withDivider>
            <Button
              type="submit"
              variant="primary"
              data-cy="album-nested-create_save-button"
              data-name="album-nested-create_save-button"
              disabled={formMethods.watch('name').trim() === ''}
            >
              Create
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SubAlbumCreateModal;
