import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { queryKey } from 'constants/queryKey';
import { MediaApi } from 'api/media';
import Popup from 'utils/popup';
import { MediaLibraryItem, MediaLibraryItems } from 'types/MediaLibrary';
import { useToast } from 'app/hooks/useToast';
import { pluralize } from 'utils/lang';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import { useEffect, useRef } from 'react';
import { useMediaAttachmentsUploader } from 'utils/hooks/useMediaAttachmentsUploader';
import { useImageEditor } from 'app/components/imageEditor/hooks/useImageEditor';
import PostsConfig from 'app/config/posts';
import { useAlbumsMediaCountOptimisticUpdate } from 'app/modules/mediaLibrary/hooks/useAlbumsMediaCountOptimisticUpdate';

type Props = {
  albumId?: number;
  page: {
    id: number;
    type: number;
  };
};

const DEFAULT_TAKE_LIMIT = 20;

export function useLibraryMediaListContainer(props: Props) {
  const { mutate } = useMutation(MediaApi.deleteMedium);
  const toast = useToast();
  const queryClient = useQueryClient();
  const paginatedMedia = usePaginatedMediaAttachments(props);
  const { decreaseMediaCount, increaseMediaCount } =
    useAlbumsMediaCountOptimisticUpdate();
  const mediaUploader = useMediaAttachmentsUploader({
    noClick: true,
    albumId: props.albumId,
    page: props.page,
    maxFiles: PostsConfig.MAX_FILES_TO_ACCEPT,
    allowedFiles: ['image', 'video'],
    onMediaUploaded: ({ result }) => {
      if (result) {
        queryClient.setQueryData<InfiniteData<MediaLibraryItems> | undefined>(
          queryKey.albumMedia(props.albumId),
          (prevData) => {
            if (!prevData) return prevData;

            const firstPageMediums = [result, ...prevData.pages[0]];

            return {
              ...prevData,
              pages: [firstPageMediums, ...prevData.pages.slice(1)],
            };
          },
        );

        increaseMediaCount({
          albumId: props.albumId,
          medium: result,
        });
      }
    },
  });
  const imageEditor = useImageEditor();

  useEffect(() => {
    return () => {
      paginatedMedia.resetPagination();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.albumId]);

  async function deleteMedium(medium: MediaLibraryItem) {
    if (medium.type === 'image' && medium.meta.usages > 0) {
      toast(
        `You cant remove this media. It's used in ${pluralize(
          medium.meta.usages,
          'post',
        )}.`,
      );
      return;
    }

    const { isConfirmed } = await Popup.confirm();

    if (isConfirmed) {
      mutate(medium.id);

      (function removeFromAlbumMedia() {
        queryClient.setQueryData<{ pages?: MediaLibraryItems[] } | undefined>(
          queryKey.albumMedia(props.albumId),
          (prevData) => {
            if (!prevData?.pages) return prevData;

            return {
              ...prevData,
              pages: prevData.pages.map((mediaAttachmentsPage) =>
                mediaAttachmentsPage.filter(
                  (mediaAttachment) => mediaAttachment.id !== medium.id,
                ),
              ),
            };
          },
        );
        decreaseMediaCount({
          albumId: props.albumId,
          medium,
        });
      })();

      toast('Removed');
    }
  }

  return {
    deleteMedium,
    paginatedMedia,
    mediaUploader,
    ...imageEditor,
  };
}

function usePaginatedMediaAttachments(props: Props) {
  const offset = useRef(0);

  const media = useInfiniteQuery(
    queryKey.albumMedia(props.albumId),
    ({ pageParam = offset.current }) =>
      MediaApi.getAlbumMedia({
        albumId: props.albumId as number,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      enabled: Number.isFinite(props.albumId),
      getNextPageParam: (lastPage) => {
        return lastPage.length < DEFAULT_TAKE_LIMIT
          ? undefined
          : offset.current;
      },
      onSuccess({ pages }) {
        const lastPage = pages[pages.length - 1];

        if (lastPage.length === DEFAULT_TAKE_LIMIT) {
          offset.current += DEFAULT_TAKE_LIMIT;
        }
      },
    },
  );
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: media.isLoading || media.isFetchingNextPage,
    onLoadMore: media.fetchNextPage,
    hasNextPage: media.hasNextPage ?? false,
  });

  function resetPagination() {
    offset.current = 0;
    media.remove();
  }

  return {
    media: media.data?.pages.flat() ?? [],
    isLoading: media.isLoading,
    isError: media.isError,
    hasNextPage: media.hasNextPage,
    resetPagination,
    sentryRef,
    rootRef,
  };
}
