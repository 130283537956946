import React, { forwardRef } from 'react';
import ReactPlayer from 'react-player/file';
import PostsConfig from 'app/config/posts';
import clsx from 'clsx';
import { VideoMediumAttachmentCard } from 'types/Attachments';
import { VideoPlayerRef } from '@kontentino/kontentino-post-previews';

type Props = {
  video: VideoMediumAttachmentCard;
  canShowThumbnail: boolean;
  thumbnailSrc?: string;
};

const VideoThumbnailSetupModalThumbnailPreview = forwardRef<
  VideoPlayerRef,
  Props
>((props, ref) => {
  return (
    <div className="tw-pointer-events-none tw-relative tw-select-none tw-overflow-hidden tw-rounded">
      <ReactPlayer
        // @ts-ignore
        ref={ref}
        width="100%"
        height="auto"
        url={props.video.sizes.medium?.src}
        playing={false}
        loop={false}
        controls={false}
        muted
        config={{
          attributes: {
            id: PostsConfig.POST_COMPOSE_THUMBNAIL_SETUP_VIDEO_PLAYER_ID,
            crossOrigin: 'anonymous',
            controlsList: 'nodownload noremoteplayback',
            disablePictureInPicture: true,
          },
        }}
      />
      <div
        className={clsx(
          'tw-pointer-events-none tw-absolute tw-left-0 tw-top-0 tw-z-50 tw-h-full tw-w-full tw-select-none tw-bg-cover tw-bg-center tw-bg-no-repeat',
          {
            'tw-opacity-0': !props.canShowThumbnail,
          },
        )}
        style={{
          backgroundImage: `url('${props.thumbnailSrc}')`,
        }}
      />
    </div>
  );
});

export default VideoThumbnailSetupModalThumbnailPreview;
