import Skeleton from 'react-loading-skeleton';
import React, { ForwardRefExoticComponent } from 'react';

type Props = {
  className?: string;
  ref: any;
};

const LoadingMoreMedia: ForwardRefExoticComponent<Props> = React.forwardRef(
  (props, ref) => {
    return (
      // @ts-ignore
      <div className={props.className} ref={ref}>
        <Skeleton height="100%" />
      </div>
    );
  },
);

export default LoadingMoreMedia;
