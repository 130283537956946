import { get, httpDelete, patch, post } from 'api/client';
import env from 'config';
import {
  AssetsParams,
  MediaAlbum,
  MediaAlbumDetail,
  MediaAlbumParams,
  MediaAlbumsParams,
  MediaAsset,
} from '../types';

export const MediaLibraryApi = {
  getAllAlbums(
    params?: MediaAlbumsParams & {
      sort_by?: string;
      limit?: number;
      sort_direction?: 'asc' | 'desc';
      page?: number;
    },
  ): Promise<ApiResponse<MediaAlbum[]>> {
    return get(`/media-library/albums`, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params,
    });
  },

  getAlbum(id: string): Promise<MediaAlbumDetail> {
    return get(`/media-library/albums/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  createAlbum({
    name,
    description,
    parent_id,
    assigned_model_id,
    assigned_model_name,
    assigned_model_type,
  }: MediaAlbumParams): Promise<MediaAlbum> {
    const data = new FormData();
    data.append('name', name);
    if (description) {
      data.append('description', description);
    }
    if (assigned_model_id && assigned_model_name && assigned_model_type) {
      data.append('assigned_model_id', assigned_model_id);
      data.append('assigned_model_name', assigned_model_name);
      data.append('assigned_model_type', assigned_model_type);
    }

    if (parent_id) {
      data.append('parent_id', parent_id);
    }

    return post(`/media-library/albums`, data, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  duplicateAlbum({
    id,
    data: { name, assigned_model_id, assigned_model_name, assigned_model_type },
  }: {
    id: string;
    data: MediaAlbumParams;
  }): Promise<MediaAlbum> {
    const data = new FormData();
    data.append('name', name);

    if (assigned_model_id && assigned_model_name && assigned_model_type) {
      data.append('assigned_model_id', assigned_model_id);
      data.append('assigned_model_name', assigned_model_name);
      data.append('assigned_model_type', assigned_model_type);
    }

    return post(`/media-library/albums/${id}/duplicate`, data, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  updateAlbum({
    id,
    data: {
      name,
      description,
      assigned_model_id,
      assigned_model_name,
      assigned_model_type,
    },
  }: {
    id: string;
    data: MediaAlbumParams;
  }): Promise<ApiResponse<MediaAlbum>> {
    const data = {
      name,
      description,
      assigned_model_id,
      assigned_model_name,
      assigned_model_type,
    };
    return patch(`/media-library/albums/${id}`, data, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  deleteAlbum: (
    id: string,
  ): Promise<{
    data: [];
    status: true;
    errors: null;
  }> => {
    return httpDelete(`/media-library/albums/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  setAsMasterAlbum(id: string): Promise<{
    data: MediaAlbum;
    status: true;
    errors: null;
  }> {
    return post(`/media-library/albums/${id}/set-as-master-album`, null, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },

  getAssets(params?: AssetsParams): Promise<ApiResponse<MediaAsset[]>> {
    return get(`/media-library/assets`, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params,
    });
  },

  deleteAsset: (
    id: string,
  ): Promise<{
    data: [];
    status: true;
    errors: null;
  }> => {
    return httpDelete(`/media-library/assets/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
};
