import FilesUtil from 'utils/files';
import { Icon, IconButton, IconButtonProps } from '@kontentino/ui';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import React, { FC } from 'react';
type Props = {
  file: File;
  onRemoveClick: IconButtonProps['onClick'];
};

const BaseFileInfo: FC<Props> = ({ file, onRemoveClick }) => {
  return (
    <div className="tw-flex tw-h-[40px] tw-w-full tw-items-center tw-justify-between tw-overflow-hidden tw-rounded tw-border tw-border-grayscale-30">
      <span className="tw-mx-3 tw-flex tw-items-center tw-gap-1 tw-text-md">
        <span className="tw-max-w-[200px] tw-truncate">{file.name}</span>
        <span className="tw-whitespace-nowrap tw-text-grayscale-50">
          ({FilesUtil.formatBytes(file.size, 0)})
        </span>
      </span>
      <IconButton
        onClick={onRemoveClick}
        icon={<Icon icon={faTrashAlt} />}
        variant="secondary"
        className="tw-rounded-l-none tw-border-l tw-border-l-grayscale-30"
      />
    </div>
  );
};

export default BaseFileInfo;
