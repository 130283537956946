import { useEffect, useState } from 'react';
import PQueue from 'p-queue';
import {
  RunningUpload,
  RunningUploadsRecord,
  UploadSequence,
} from 'types/RunningUpload';
import FilesUtil from 'utils/files';
import { useUser } from 'modules/user/userSelector';
import { useAccount } from 'modules/account/accountSelector';
import { MediaLibraryItemFileType } from 'types/MediaLibrary';
import MediaUtils from 'app/utils/media';
import { RunningUploadStatus } from 'constants/runningUpload';
import AppConfig from 'app/config/app';
import { createRunningUploads } from 'app/utils/runningUploads';
import { useToast } from '../../app/hooks/useToast';
import {
  useFileDropzone,
  UseFileDropzoneOptions,
} from 'app/hooks/useFileDropzone';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';

type Props = Pick<UseFileDropzoneOptions, 'maxFiles' | 'noClick'> & {
  page: {
    id: number;
    type: number;
  };
  albumId?: number;
  onMediaUploaded?: (p: RunningUpload) => void;
  uploadSequence?: UploadSequence;
  allowedFiles: MediaLibraryItemFileType[];
};

export function useMediaAttachmentsUploader({
  page,
  albumId,
  uploadSequence = 'lifo',
  onMediaUploaded,
  allowedFiles,
  maxFiles,
  noClick,
}: Props) {
  const toast = useToast();
  const [uploadQueue] = useState(new PQueue(getQueueOptions(uploadSequence)));
  const [runningUploads, setRunningUploads] = useState<RunningUploadsRecord>(
    {},
  );
  const [isRunning, setIsRunning] = useState(false);
  const user = useUser();
  const account = useAccount();

  useEffect(() => {
    // update state every 2 seconds if queue is working to show progress
    const interval = setInterval(() => {
      const isQueueAtIdle = uploadQueue.size === 0 && uploadQueue.pending === 0;

      if (!isQueueAtIdle) {
        updateRunningUploads();
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);

  const dropzone = useFileDropzone({
    onDrop: (files) => upload({ files }),
    accept: MediaUtils.getAcceptedAttachmentsByFileType(allowedFiles).accept,
    multiple: maxFiles !== 1,
    noClick,
    maxFiles,
  });

  useEffectOnce(() => {
    uploadQueue.on('next', updateRunningUploads);
    uploadQueue.on('idle', () => setIsRunning(false));
    uploadQueue.on('add', () => setIsRunning(true));

    return () => {
      resetUploads();
    };
  });

  useEffect(() => {
    uploadQueue.on('completed', (res: RunningUpload) => {
      removeRunningUploads([res.id]);
      onMediaUploaded?.(res);

      if (res.status === RunningUploadStatus.Failed && res.error) {
        toast(res.error, 'error');
      }
    });

    return () => {
      uploadQueue.removeAllListeners('completed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMediaUploaded]);

  function getValidFiles(files: File[]) {
    const acceptedMediaAttachments =
      MediaUtils.getAcceptedAttachmentsByFileType(allowedFiles);

    return FilesUtil.detectFiles(
      FilesUtil.filterFilesByOptions(files, {
        extensions: acceptedMediaAttachments.extensions,
        mimeTypes: acceptedMediaAttachments.mimeTypes,
      }),
    );
  }

  async function upload({ files }: { files?: File[] }) {
    const { runningUploads, jobs } = await createRunningUploads({
      upload: {
        files: getValidFiles(files ?? []),
      },
      options: {
        pageId: page.id,
        albumId,
        userId: user.id,
        userAccountPublicId: account.publicId,
        uploadSequence,
      },
    });

    updateRunningUploads(runningUploads);
    uploadQueue.addAll(jobs);
  }

  function updateRunningUploads(newRunningUploads?: RunningUploadsRecord) {
    setRunningUploads((prev) => ({ ...newRunningUploads, ...prev }));
  }

  function removeRunningUploads(ids: string | string[]) {
    setRunningUploads((prev) => {
      const toDelete = Array.isArray(ids) ? ids : [ids];

      toDelete.forEach((id) => delete prev[id]);

      return { ...prev };
    });
  }

  function resetUploads() {
    setRunningUploads((prev) => {
      Object.values(prev).forEach((upload) => upload.cancelHandler.cancel());
      uploadQueue.clear();

      return {};
    });

    setIsRunning(false);
  }

  return {
    runningUploads: Object.values(runningUploads),
    upload,
    dropzone,
  };
}

const SERIAL_UPLOAD_CONCURRENCY = 1;

function getQueueOptions(uploadSequence?: UploadSequence) {
  return {
    concurrency:
      uploadSequence === 'parallel'
        ? AppConfig.ATTACHMENTS_MEDIA_UPLOAD_PARALLEL_CONCURRENCY
        : SERIAL_UPLOAD_CONCURRENCY,
    autoStart: true,
  };
}
