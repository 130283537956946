import { faPhotoFilm } from '@fortawesome/pro-regular-svg-icons/faPhotoFilm';
import { Icon } from '@kontentino/ui';
import React, { FC } from 'react';

const NoAssetsFound: FC = () => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <div className="tw-flex tw-h-12 tw-w-12 tw-items-center  tw-justify-center tw-rounded-full tw-bg-primary-10">
        <Icon icon={faPhotoFilm} size={24} className="  tw-text-primary-100" />
      </div>
      <p className="tw-mt-3 tw-text-md"> No assets found</p>
    </div>
  );
};

export default NoAssetsFound;
