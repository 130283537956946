import { MediaLibraryItem, MediaLibraryItemFileType } from 'types/MediaLibrary';
import { useDefaultPageAlbumId } from 'app/modules/posts/hooks/useDefaultPageAlbumId';
import { useMediaAttachmentsUploader } from 'utils/hooks/useMediaAttachmentsUploader';
import { FileSource } from 'app/components/fileSourceInput/types/fileSource';
import { Icon } from '@kontentino/ui';
import { faLaptop } from '@fortawesome/pro-regular-svg-icons/faLaptop';
import { faPhotoFilm } from '@fortawesome/pro-regular-svg-icons/faPhotoFilm';
import React from 'react';
import { UploadSequence } from 'types/RunningUpload';
import useBoolean from 'utils/hooks/useBoolean';
export type UseFileSourcesProps = {
  page: {
    id: number;
    type: number;
  };
  albumId?: number;
  onChange: (media: MediaLibraryItem[]) => void;
  maxFiles: number;
  allowedFiles: MediaLibraryItemFileType[];
  uploadSequence?: UploadSequence;
};

export function useFileSource({
  page,
  albumId,
  maxFiles,
  allowedFiles,
  onChange,
  uploadSequence,
}: UseFileSourcesProps) {
  const defaultAlbumId = useDefaultPageAlbumId(page.id);
  const [isShown, setIsShown] = useBoolean(false);
  const mediaUploader = useMediaAttachmentsUploader({
    page,
    albumId: albumId ?? defaultAlbumId,
    onMediaUploaded: (item) => {
      if (item.result) {
        onChange([item.result]);
      }
    },
    allowedFiles,
    maxFiles,
    uploadSequence,
  });

  function getFileSources() {
    const fileSources: [FileSource, ...FileSource[]] = [
      {
        shortLabel: 'From device',
        label: (
          <>
            <Icon icon={faLaptop} />
            From your device
          </>
        ),
        onClick: mediaUploader.dropzone.open,
        attributes: {
          dataName: 'file-source-device-option',
          dataCy: 'file-source-device-option',
        },
      },
      {
        label: (
          <>
            <Icon icon={faPhotoFilm} />
            From your media library
          </>
        ),
        onClick: setIsShown.on,
        attributes: {
          dataName: 'file-source-media-library-option',
          dataCy: 'file-source-media-library-option',
        },
      },
    ];

    return fileSources;
  }

  const fileSources = getFileSources();

  return {
    fileSources,
    mediaLibrary: {
      isShown,
      toggle: setIsShown.toggle,
      show: setIsShown.on,
      hide: setIsShown.off,
    },
    mediaUploader,
    params: {
      page,
      albumId,
      maxFiles,
      allowedFiles,
      onChange,
      uploadSequence,
    },
  };
}
