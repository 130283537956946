import React, { FC } from 'react';
import style from 'components/shared/selectPanel/SelectPanel.module.scss';
import SearchTextInput from 'app/components/SearchTextInput';
import clsx from 'clsx';

type Props = {
  searchValue: string;
  onSearchChange: AnyFunction;
  searchPlaceholder?: string;
  isSearchAutoFocus?: boolean;
  children: React.ReactNode | React.ReactNode[] | null;
  className?: string;
};

const SelectPanel: FC<Props> = ({
  searchValue,
  onSearchChange,
  searchPlaceholder,
  isSearchAutoFocus = true,
  className,
  children,
}) => {
  return (
    <div className={clsx(style.panel, className)}>
      <div className={style.input}>
        <SearchTextInput
          onChange={(e) => onSearchChange(e.target.value)}
          value={searchValue}
          placeholder={searchPlaceholder}
          autoFocus={isSearchAutoFocus}
        />
      </div>
      {children}
    </div>
  );
};

export default SelectPanel;
