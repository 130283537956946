import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import { faGif } from '@fortawesome/pro-regular-svg-icons/faGif';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import TimeUtils from 'app/utils/time';
import { MediaLibraryItem } from 'types/MediaLibrary';
type Props = {
  medium: MediaLibraryItem;
};

const MediaPreviewTypeIndicator: FC<Props> = ({ medium }) => {
  function getIndicator() {
    if (medium?.type === 'video') {
      return (
        <div className="tw-flex tw-items-center tw-gap-1">
          <Icon icon={faVideo} />

          {medium.meta?.duration && (
            <span className="tw-text-sm">
              {TimeUtils.formatMilliSeconds(
                TimeUtils.calcMilliseconds.seconds(medium.meta.duration),
              )}
            </span>
          )}
        </div>
      );
    }

    if (medium?.type === 'image') {
      return medium.meta.is_animated_gif ? <Icon icon={faGif} /> : null;
    }

    return null;
  }

  const indicator = getIndicator();

  if (indicator !== null) {
    return (
      <div className="tw-flex tw-min-h-[1rem] tw-min-w-[1rem] tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5 tw-px-1 tw-text-xs tw-font-semibold tw-text-grayscale-100">
        {indicator}
      </div>
    );
  }

  return null;
};

export default MediaPreviewTypeIndicator;
