import { Select } from '@kontentino/ui';
import React, { FC } from 'react';
import AssetCard from './new/AssetCard';
import { MediaAsset } from '../types';

const TYPE_OPTIONS = [
  { value: 'images', label: 'Images' },
  { value: 'videos', label: 'Videos' },
  { value: 'gifs', label: 'Gifs' },
];
const SIZES_OPTIONS = [
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
];
const ORIENTATIONS_OPTIONS = [
  { value: 'horizontal', label: 'Horizontal' },
  { value: 'vertical', label: 'Vertical' },
  { value: 'square', label: 'Square' },
];

type Props = {
  assets: MediaAsset[];
};
const Assets: FC<Props> = ({ assets }) => {
  return (
    <div>
      <div className="tw-mb-6 tw-flex tw-flex-col">
        <h2 className="tw-mb-1 tw-text-base tw-font-semibold">Assets</h2>
        <div className="tw-flex tw-gap-3">
          {/* TODO: plurals */}
          <span className="tw-text-sm tw-text-grayscale-100">138 Images</span>
          <span className="tw-text-sm tw-text-grayscale-100">84 Videos</span>
          <span className="tw-text-sm tw-text-grayscale-100">18 Gifs</span>
        </div>
      </div>

      <div className="tw-mb-6 tw-flex tw-justify-between">
        <div className="tw-flex tw-gap-3">
          <Select isMulti placeholder="All profiles" />
          <Select placeholder="Date added" />
        </div>
        <div className="tw-flex tw-gap-3">
          <Select placeholder="All types" options={TYPE_OPTIONS} />
          <Select placeholder="All sizes" options={SIZES_OPTIONS} />
          <Select
            placeholder="All orientations"
            options={ORIENTATIONS_OPTIONS}
          />
        </div>
      </div>

      <div className="tw-flex tw-flex-wrap tw-gap-4">
        {assets &&
          assets.length > 0 &&
          assets.map((asset) => <AssetCard key={asset.id} asset={asset} />)}
      </div>
    </div>
  );
};

export default Assets;
