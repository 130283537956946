import React, { FC } from 'react';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import FilesUtil from 'utils/files';
import {
  CROP_PRESETS,
  SOCIAL_MEDIA_PRESETS_GROUPS,
} from './imageEditor/imageEditorConfig';
import style from './imageEditor/ImageEditor.module.scss';
import FilerobotImageEditor from 'react-filerobot-image-editor';
import Modal from 'components/shared/Modal';
import './imageEditor/filerobotImageEditor.scss';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { ImageTemplatesApi } from 'api/imageTemplates';
import ImageTemplatesUtil from 'utils/imageTemplates';

type Props = {
  imageSrc: string;
  onClose(): void;
  onImageEdited(file: File): void;
  pageId?: number;
};

const ImageEditor: FC<Props> = (props) => {
  const toast = useToast();
  const watermarks = useQuery(
    queryKey.pageImageTemplates(props.pageId),
    () => {
      if (!props.pageId) return Promise.resolve([]);

      return ImageTemplatesApi.get(props.pageId).then((res) =>
        ImageTemplatesUtil.formatToWatermarks(res),
      );
    },
    {
      enabled: typeof props.pageId === 'number',
    },
  );

  async function onSaveImage(result: { imageCanvas?: HTMLCanvasElement }) {
    if (result.imageCanvas) {
      try {
        const file = await FilesUtil.base64ToPngFile(
          result.imageCanvas.toDataURL('image/png'),
        );
        props.onImageEdited(file);
        props.onClose();
      } catch (e) {
        toast(i18n.somethingWentWrong, 'error');
      }
    }
  }

  return (
    <Modal classNames={{ modal: style.modal }}>
      <FilerobotImageEditor
        source={props.imageSrc}
        onClose={props.onClose}
        onBeforeSave={() => false}
        onSave={onSaveImage}
        previewPixelRatio={8}
        savingPixelRatio={8}
        Crop={{
          presetsItems: CROP_PRESETS,
          presetsFolders: [
            {
              titleKey: 'socialMedia',
              groups: SOCIAL_MEDIA_PRESETS_GROUPS,
            },
          ],
        }}
        Watermark={
          watermarks.data
            ? { gallery: watermarks.data.map((watermark) => watermark.url) }
            : undefined
        }
      />
    </Modal>
  );
};

export default ImageEditor;
