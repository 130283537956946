import { usePostComposer } from 'app/modules/posts/modules/post/components/composer/PostComposerProvider';
import { VideoThumbnail } from 'types/MediaLibrary';
import { AttachmentCard } from 'types/Attachments';

const useSetVideoThumbnailPostComposerForm = () => {
  const { setForm } = usePostComposer();

  return (thumbnail: VideoThumbnail | null) => {
    setForm((prev) => ({
      attachments: {
        ...prev.attachments,
        cards: prev.attachments.cards.map((attachment) => ({
          ...attachment,
          medium: {
            ...attachment.medium,
            custom_thumb: thumbnail,
          },
        })) as AttachmentCard[],
      },
    }));
  };
};

export default useSetVideoThumbnailPostComposerForm;
