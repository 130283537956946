import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import {
  BrandLogo,
  DropdownMenu,
  Icon,
  IconButton,
  SocialMediaIconProps,
  colors,
  mergeTailwindClasses,
} from '@kontentino/ui';
import { queryClient } from 'api/client';
import { useToast } from 'app/hooks/useToast';
import PageTypeUtils from 'app/utils/pageType';
import routes from 'constants/routes';
import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { stopPropagation } from 'utils/mouseEvent';
import Popup from 'utils/popup';
import { createSearchFromParams } from 'utils/url';
import AlbumDuplicateModal from './AlbumDuplicateModal';
import AlbumEditModal from './AlbumEditModal';
import { MediaLibraryApi } from '../../api/mediaLibrary';
import { MediaAlbum } from '../../types';
import Logger from 'utils/logger';
import AlbumAssignModal from './AlbumAssignModal';

export type AlbumCardProps = {
  album: MediaAlbum;
  className?: string;
  isSubAlbum?: boolean;
};

const AlbumCard: FC<AlbumCardProps> = ({ className, album, isSubAlbum }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);

  Logger.log('albumData', album);

  const setAsMasterAlbum = useMutation(
    () => MediaLibraryApi.setAsMasterAlbum(album.id),
    {
      onSuccess() {
        queryClient.invalidateQueries('allAlbums');
      },
      onError() {
        toast('Failed to set the album as master.', 'error');
      },
    },
  );
  const deleteAlbum = useMutation(() => MediaLibraryApi.deleteAlbum(album.id), {
    onSuccess() {
      queryClient.invalidateQueries('allAlbums');
    },
    onError() {
      toast('Unable to delete album.', 'error');
    },
  });

  async function onDeleteAlbum() {
    const { isConfirmed } = await Popup.confirm({
      title: ' Are you sure you want to delete this album?',
      text: 'Deleting this album will also remove all of its subfolders.',
      showCancelButton: true,
      confirmButtonColor: colors.danger.default,
      confirmButtonText: 'Delete',
      width: '400px',
    });

    if (isConfirmed) {
      deleteAlbum.mutate();
    }
  }

  async function onSetAsMaster() {
    const { isConfirmed } = await Popup.confirm({
      title: ' Are you sure you want to make this album a master album?',
      showCancelButton: true,
      // confirmButtonColor: colors.danger.default,
      confirmButtonText: 'Create',
      width: '400px',
    });

    if (isConfirmed) {
      setAsMasterAlbum.mutate();
    }
  }

  return (
    <>
      <div
        onClick={() =>
          navigate({
            pathname: routes.ALBUM_DETAIL,
            search: createSearchFromParams({
              id: album.id,
            }),
          })
        }
        className={mergeTailwindClasses(
          ' tw-group tw-relative tw-flex tw-w-[324px] tw-cursor-pointer tw-items-center tw-gap-3 tw-rounded-md tw-bg-white tw-px-4 tw-py-2 hover:tw-bg-primary-10',
          className,
        )}
      >
        <div className="tw-relative">
          {!!album.assigned_to?.name && album.cover_image_url ? (
            <BrandLogo
              src={album.cover_image_url}
              size={24}
              socialMediaType={
                PageTypeUtils.getName(
                  album.assigned_to?.type,
                ) as SocialMediaIconProps['type']
              }
            />
          ) : (
            <Icon size={20} className="tw-text-grayscale-100" icon={faFolder} />
          )}
        </div>

        <div className="tw-flex tw-flex-col">
          <span className="tw-text-md tw-font-semibold tw-text-grayscale-180 group-hover:tw-text-primary-100">
            {album.name}
          </span>
          <span className="tw-text-sm tw-text-grayscale-140">
            {/* TODO: plurals */}
            {album.stats.images_count} images,
            {album.stats.videos_count} videos,
          </span>
        </div>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton
              icon={<Icon icon={faEllipsisVertical} />}
              size="small"
              variant="secondary"
              onClick={stopPropagation()}
              className="tw-absolute tw-right-4 tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100"
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="tw-z-10 tw-w-56">
            {/* <DropdownMenu.Label>Album</DropdownMenu.Label> */}
            <DropdownMenu.Item
              onClick={stopPropagation(() => setOpenEditModal(true))}
            >
              <Icon icon={faEdit} />
              Edit
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={stopPropagation(() => setOpenDuplicateModal(true))}
            >
              <Icon icon={faCopy} />
              Duplicate
            </DropdownMenu.Item>
            {!isSubAlbum && (
              <DropdownMenu.Item
                onClick={stopPropagation(() => setOpenAssignModal(true))}
              >
                <Icon icon={faFolder} />
                Assign
              </DropdownMenu.Item>
            )}
            {isSubAlbum && (
              <DropdownMenu.Item
                onClick={stopPropagation(() => onSetAsMaster())}
              >
                <Icon icon={faFolder} />
                Make a master album
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Item onClick={stopPropagation()} disabled={true}>
              <Icon icon={faDownload} />
              Download all assets
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={stopPropagation(() => onDeleteAlbum())}>
              <Icon icon={faTrash} />
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
      {openAssignModal && (
        <AlbumAssignModal
          isOpen={openAssignModal}
          onClose={() => setOpenAssignModal(false)}
          initialData={{
            name: album.name,
            description: album.description || undefined,
            assigned_model_id: album.assigned_to?.id.toString() || '',
            assigned_model_name: 'Page',
            assigned_model_type: album.assigned_to?.type.toString() || '',
          }}
          albumId={album.id}
        />
      )}

      {openDuplicateModal && (
        <AlbumDuplicateModal
          onClose={() => setOpenDuplicateModal(false)}
          isOpen={openDuplicateModal}
          albumId={album.id}
          initialData={{
            name: album.name || '',
            description: album.description || undefined,
            assigned_model_id: album.assigned_to?.id.toString() || '',
            assigned_model_name: 'Page',
            assigned_model_type: album.assigned_to?.type.toString() || '',
          }}
        />
      )}

      {openEditModal && (
        <AlbumEditModal
          isOpen={openEditModal}
          onClose={() => setOpenEditModal(false)}
          initialData={{
            name: album.name,
            description: album.description || undefined,
            assigned_model_id: album.assigned_to?.id.toString() || '',
            assigned_model_name: 'Page',
            assigned_model_type: album.assigned_to?.type.toString() || '',
          }}
          albumId={album.id}
        />
      )}
    </>
  );
};

export default AlbumCard;
