import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { faFeather } from '@fortawesome/pro-regular-svg-icons/faFeather';
import UserPermissionGate from 'components/UserPermissionGate';
import { FC } from 'react';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faPenLine } from '@fortawesome/pro-regular-svg-icons/faPenLine';
import { faFolderImage } from '@fortawesome/pro-regular-svg-icons/faFolderImage';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { useActions } from 'app/hooks/useActions';

type Props = {
  isOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
  assetId: string;
};

const AssetPreviewDropdown: FC<Props> = ({
  isOpen,
  setIsDropdownOpen,
  assetId,
}) => {
  const { assetPreview } = useActions('asset-preview');

  const dropdownOptions = [
    ...[
      {
        key: 'edit',
        label: 'Edit',
        icon: faPenToSquare,
        permissionKeys: [],
        // onClick: profileActions.add.perform,
      },
      {
        key: 'rename',
        label: 'Rename',
        icon: faPenLine,
        permissionKeys: [],
        // link: { hash: modalRoutes.MANAGE_PROFILE_GROUP },
      },
      {
        key: 'duplicate',
        label: 'Duplicate',
        icon: faFeather,
        permissionKeys: [],
        // link: getCreateInspirationUrl(),
      },
      {
        key: 'move-to-album',
        label: 'Move to album',
        icon: faFolderImage,
        permissionKeys: [],
        // link: { hash: modalRoutes.NEW_CONTENT_PLAN },
      },

      {
        key: 'delete',
        label: assetPreview.delete.label,
        icon: assetPreview.delete.icon,
        permissionKeys: assetPreview.delete.scopes,
        // link: { hash: modalRoutes.NEW_CONTENT_PLAN },
        onClick: () => assetPreview.delete.perform({ id: assetId }),
      },
    ],
  ];

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenu.Trigger>
        <IconButton
          variant="secondary"
          size="small"
          icon={<Icon icon={faEllipsisVertical} />}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10 tw-w-56">
        {dropdownOptions.map((option) => (
          <UserPermissionGate
            scopes={option.permissionKeys}
            key={`create-new-${option.key}-option`}
          >
            <DropdownMenu.Item
              data-name={`asset-preview-${option.key}`}
              data-cy={`asset-preview-${option.key}-button`}
              className="tw-group"
              onClick={option.onClick}
            >
              {/* <Icon icon={option.icon} /> */}
              {option.label}
            </DropdownMenu.Item>
          </UserPermissionGate>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default AssetPreviewDropdown;
