import React, { FC } from 'react';
import i18n from 'i18n';
import { Alert } from '@kontentino/ui';

type Props = {
  type: 'empty' | 'error';
};

const MediaListAlertMessage: FC<Props> = (props) => {
  return (
    <div className="tw-flex tw-flex-1 tw-items-center tw-justify-center">
      <Alert variant={props.type === 'empty' ? 'info' : 'danger'}>
        {props.type === 'empty'
          ? "This album doesn't contain any media"
          : i18n.somethingWentWrongTryAgain}
      </Alert>
    </div>
  );
};

export default MediaListAlertMessage;
